import { useReactQueryState } from '../../../main/common/hooks/base/reactQuery/useReactQueryState';

export const MAIN_SCROLL_CACHE_KEY = 'mainScroll';

function useMainScrollElement() {
  const { setValue, value } = useReactQueryState<HTMLElement>(
    MAIN_SCROLL_CACHE_KEY
  );

  return {
    mainScrollElement: value,
    setMainScrollElement: setValue
  };
}

export default useMainScrollElement;
