import { useCallback, useState } from 'react';
import some from 'lodash/some';

import { TaskNanoID, TaskResultsFields } from '../../../../../tasksTypes';

import {
  SUBMIT_RESULTS_TO_TASK_QUERY,
  SubmitResultsToTaskQueryResponse
} from '../../../../../queries/submitResultsToTask.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import { useSubmitResultsToTask } from '../../../../../hooks/useSubmitResultsToTask';

import { SubmitResultsToTaskFormData } from '../../SubmitResultsToTaskForm.types';

import { submitResultsToTaskValidationSchema } from './useSubmitResultsToTaskForm.schema';

import { DropzoneHelperFiles } from '../../../../../../../helpers/dropzone/DropzoneHelper';

import { TaskCache } from '../../../../../TaskCache';

const submitResultsToTaskFormData: SubmitResultsToTaskFormData = {
  fileAttachmentIds: [],
  hours: '',
  minutes: '',
  body: ''
};

interface SubmitResultsToTaskFormOptions {
  taskNanoId: TaskNanoID;
}

function useSubmitResultsToTaskForm({
  taskNanoId
}: SubmitResultsToTaskFormOptions) {
  const { handleSubmitReactHookForm, register, errors, control, resetForm } =
    useReactHookForm<SubmitResultsToTaskFormData>({
      defaultValues: submitResultsToTaskFormData,
      isModalForm: true,
      schema: submitResultsToTaskValidationSchema
    });

  const {
    submitResultsToTask,
    submitResultsToTaskLoading,
    submitResultsToTaskErrorMessage,
    submitResultsToTaskReset
  } = useSubmitResultsToTask<SubmitResultsToTaskQueryResponse>({
    query: SUBMIT_RESULTS_TO_TASK_QUERY,
    cacheKeys: [
      TaskCache.indexCacheKey(),
      TaskCache.showQueryKey(taskNanoId),
      TaskCache.messagesCacheKey(taskNanoId),
      TaskCache.itemsResultsCacheKey(),
      TaskCache.withoutSourceFiles(),
      TaskCache.dashboardWithoutSourceFiles(),
      TaskCache.userResultsCacheKey(),
      TaskCache.userActiveTasksCacheKey(),
      TaskCache.activeTaskCacheKey(taskNanoId)
    ]
  });

  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);

  const handleChangeFiles = useCallback<(files: DropzoneHelperFiles) => void>(
    (files) => {
      setLoadingFiles(some(files, (file) => file.state === 'processing'));
    },
    []
  );

  return {
    control,
    submitResultsToTask,
    submitResultsToTaskLoading,
    submitResultsToTaskErrorMessage,
    submitResultsToTaskReset,
    resetForm,
    handleSubmitResultsToTask: handleSubmitReactHookForm({
      onSubmit: async (data: SubmitResultsToTaskFormData) =>
        submitResultsToTask({
          uuid: taskNanoId,
          ...data,
          hours: data.hours === '' ? undefined : parseFloat(data.hours),
          minutes: data.minutes === '' ? undefined : parseFloat(data.minutes)
        })
    }),
    registerFileAttachmentIds: register(TaskResultsFields.FILE_ATTACHMENT_IDS),
    registerHours: register(TaskResultsFields.HOURS),
    registerMinutes: register(TaskResultsFields.MINUTES),
    registerBody: register(TaskResultsFields.BODY),
    validationErrors: {
      fileAttachmentIdsValidationError: errors?.fileAttachmentIds?.['message'],
      minutesValidationError: errors?.minutes?.message,
      hoursValidationError: errors?.hours?.message
    },
    loadingFiles,
    handleChangeFiles
  };
}

export default useSubmitResultsToTaskForm;
