import React from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { TaskNanoID } from '../../../tasksTypes';

import {
  DropzoneHelperFileIds,
  DropzoneHelperFiles
} from '../../../../../helpers/dropzone/DropzoneHelper';

import { useSubmitResultsToTaskForm } from '../../forms/SubmitResultsToTaskForm/hooks/useSubmitResultsToTaskForm';

import { SubmitResultsToTaskForm } from '../../forms/SubmitResultsToTaskForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { tasksKeys, words } from '../../../../../locales/keys';

interface SubmitResultsToTaskModalButtonProps {
  taskNanoId: TaskNanoID;
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  initialFiles?: DropzoneHelperFiles;
  initialFileIds?: DropzoneHelperFileIds;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
}

const SUBMIT_TASK_RESULTS_FORM = 'submit-task-results-form';

function SubmitResultsToTaskModalButton({
  taskNanoId,
  className,
  icon,
  iconClassName,
  initialFiles,
  initialFileIds,
  i18nText,
  tooltipI18nText
}: SubmitResultsToTaskModalButtonProps) {
  const {
    control,
    resetForm,
    handleSubmitResultsToTask,
    submitResultsToTaskErrorMessage,
    submitResultsToTaskLoading,
    submitResultsToTaskReset,
    registerHours,
    registerMinutes,
    registerBody,
    validationErrors,
    loadingFiles,
    handleChangeFiles
  } = useSubmitResultsToTaskForm({
    taskNanoId
  });

  return (
    <FormModalButton
      i18nText={i18nText}
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      form={SUBMIT_TASK_RESULTS_FORM}
      icon={icon}
      iconClassName={iconClassName}
      i18nSubmitText={words.submit}
      i18nTitle={tasksKeys.submitResults}
      tooltipI18nText={tooltipI18nText}
      isLoading={submitResultsToTaskLoading}
      onOpen={resetForm}
      submitDisabled={loadingFiles}
      onClose={submitResultsToTaskReset}
      onSubmit={handleSubmitResultsToTask}
    >
      <SubmitResultsToTaskForm
        form={SUBMIT_TASK_RESULTS_FORM}
        control={control}
        isLoading={submitResultsToTaskLoading}
        registerHours={registerHours}
        registerMinutes={registerMinutes}
        registerBody={registerBody}
        fileAttachmentIdsValidationError={
          validationErrors.fileAttachmentIdsValidationError
        }
        hoursValidationError={validationErrors.hoursValidationError}
        minutesValidationError={validationErrors.minutesValidationError}
        initialFiles={initialFiles}
        initialFileIds={initialFileIds}
        onChangeFiles={handleChangeFiles}
      />

      <div className="px-4">
        <AlertMessage message={submitResultsToTaskErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default SubmitResultsToTaskModalButton;
