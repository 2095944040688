import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { MultiTrackTaskTimeModalButtonTasks } from './MultiTrackTaskTimeModalButton.types';

import { useMultiTrackTaskTimeForm } from '../../forms/MultiTrackTaskTimeForm/hooks/useMultiTrackTaskTimeForm';
import { useTranslate } from '../../../../../common/hooks/useTranslate';

import { MultiTrackTaskTimeForm } from '../../forms/MultiTrackTaskTimeForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { tasksKeys, words } from '../../../../../locales/keys';

interface MultiTrackTaskTimeModalButtonProps {
  tasks: MultiTrackTaskTimeModalButtonTasks;
  todayTrackedTime: number;
  className: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  tooltipSingleton?: boolean;
  disabled?: IsDisabled;
}

const MULTI_TRACK_TASK_TIME_FORM = 'multi_track-task-time-form';

function MultiTrackTaskTimeModalButton({
  tasks,
  todayTrackedTime,
  className,
  icon,
  iconClassName,
  i18nText,
  tooltipSingleton,
  disabled
}: MultiTrackTaskTimeModalButtonProps) {
  const { t } = useTranslate();

  const {
    trackTasksTimeLoading,
    trackTasksTimeErrorMessage,
    trackTasksTimeReset,
    resetTrackTasksTimeForm,
    handleTrackTasksTime,
    register,
    fields,
    formErrors,
    watchTotalTrackHours,
    watchTotalTrackMinutes
  } = useMultiTrackTaskTimeForm({
    initialTasks: tasks
  });

  const i18nSubmitText = `${t(tasksKeys.track)} ${watchTotalTrackHours}${t(
    words.letters.h
  )} ${watchTotalTrackMinutes}${t(words.letters.m)}`;

  return (
    <FormModalButton
      i18nText={i18nText}
      className={className}
      form={MULTI_TRACK_TASK_TIME_FORM}
      i18nSubmitText={i18nSubmitText}
      i18nTitle={tasksKeys.trackTime}
      isLoading={trackTasksTimeLoading}
      onOpen={resetTrackTasksTimeForm}
      onClose={trackTasksTimeReset}
      onSubmit={handleTrackTasksTime}
      icon={icon}
      iconClassName={iconClassName}
      tooltipI18nText={tasksKeys.trackTime}
      tooltipPlacement={TooltipPlacement.BOTTOM}
      tooltipSingleton={tooltipSingleton}
      disabled={disabled}
    >
      <div className="flex-1 overflow-y-auto px-2">
        <MultiTrackTaskTimeForm
          form={MULTI_TRACK_TASK_TIME_FORM}
          isLoading={trackTasksTimeLoading}
          errors={formErrors}
          fields={fields}
          register={register}
          tasks={tasks}
          todayTrackedTime={todayTrackedTime}
        />
      </div>

      <AlertMessage
        addClassName="mx-2 my-4"
        message={trackTasksTimeErrorMessage}
      />
    </FormModalButton>
  );
}

export default MultiTrackTaskTimeModalButton;
