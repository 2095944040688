import { Fragment } from 'react';

import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import {
  TaskName,
  TaskNanoID,
  TaskResultsUploadedAt,
  TaskSourceFilesCount,
  TaskStatus,
  TaskStatuses,
  TaskSupportMark,
  TaskUUID
} from '../../../tasksTypes';
import { ProjectName, ProjectNanoID } from '../../../../projects/projectsTypes';
import { OnLightboxOpenAction } from '../../../../../helpers/LightboxWrapper';

import { DashboardTasksResultsListItemDropdownMenu } from './components/DashboardTasksResultsListItemDropdownMenu';
import {
  DashboardTasksResultsListItemFile,
  DashboardTasksResultsListItemFileItem
} from './components/DashboardTasksResultsListItemFile';
import { AcceptTaskResultsModalButton } from '../../modalButtons/AcceptTaskResultsModalButton';

import { DownloadTaskResultsModalButton } from '../../buttons/DownloadTaskResultsModalButton';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { NextPureLinkHelper } from '../../../../../helpers/links/NextPureLinkHelper';
import { DateHelper } from '../../../../../helpers/DateHelper';

import { getCustomDateFormat } from '../../../../../utils/getCustomDateFormat';

import { TaskPath } from '../../../TaskPath';
import { ProjectPath } from '../../../../projects/ProjectPath';
import { TasksPermissions } from '../../../tasksConstants';

interface DashboardTasksResultsListItemProps {
  taskNanoId: TaskNanoID;
  taskName?: TaskName;
  taskUuid?: TaskUUID;
  projectNanoId?: ProjectNanoID;
  projectName?: ProjectName;
  resultsFileAttachments: DashboardTasksResultsListItemFileItem[];
  supportMark: TaskSupportMark;
  taskStatus: TaskStatus;
  taskSourceFilesCount: TaskSourceFilesCount;
  handleLightboxOpenOnSlide: OnLightboxOpenAction;
  resultsUploadedAt: TaskResultsUploadedAt;
}

function DashboardTasksResultsListItem({
  taskNanoId,
  taskName,
  taskUuid,
  projectNanoId,
  projectName,
  resultsUploadedAt,
  resultsFileAttachments,
  supportMark,
  taskStatus,
  taskSourceFilesCount,
  handleLightboxOpenOnSlide
}: DashboardTasksResultsListItemProps) {
  if (isEmpty(resultsFileAttachments)) {
    return null;
  }

  return (
    <div className="w-40">
      <div className="relative">
        <DashboardTasksResultsListItemFile
          key={resultsFileAttachments[0]?.uuid}
          fileAttachment={resultsFileAttachments[0]}
          onLightboxOpen={handleLightboxOpenOnSlide}
        />

        <div className="mt-3 flex flex-col leading-none px-1">
          {projectName && projectNanoId ? (
            <NextPureLinkHelper
              href={ProjectPath.results(projectNanoId)}
              text={projectName}
              className="text-xs hover:underline font-medium truncate"
            />
          ) : null}

          <NextPureLinkHelper
            href={TaskPath.results(taskNanoId)}
            text={taskName}
            className="text-xs hover:underline truncate"
          />

          <div className="text-2xs text-gray-500">
            <DateHelper
              date={resultsUploadedAt}
              customFormat={getCustomDateFormat(resultsUploadedAt)}
            />
          </div>
        </div>

        <div className="flex flex-wrap gap-1 mt-1 px-1">
          {taskStatus === TaskStatuses.READY_FOR_ACCEPTANCE && supportMark > 0 && (
            <Fragment>
              <CheckPermissions
                action={TasksPermissions.READ_ACCEPT_TASK_RESULTS_MODAL_BUTTON}
              >
                <AcceptTaskResultsModalButton
                  className="pl-1 pr-1.5 py-0.5 gap-0.5 rounded inline-flex items-center whitespace-nowrap text-xs leading-none focus:ring-base text-white bg-green-500 hover:bg-green-600 shadow-sm hover:shadow-md"
                  iconClassName="w-4 h-4"
                  taskUuid={taskUuid}
                  taskNanoId={taskNanoId}
                  withoutAccept
                />
              </CheckPermissions>
              <CheckPermissions
                action={
                  TasksPermissions.READ_FIN_ACCEPT_TASK_RESULTS_MODAL_BUTTON
                }
              >
                <AcceptTaskResultsModalButton
                  className="pl-1 pr-1.5 py-0.5 gap-0.5 rounded inline-flex items-center whitespace-nowrap text-xs leading-none focus:ring-base text-white bg-green-500 hover:bg-green-600 shadow-sm hover:shadow-md"
                  iconClassName="w-4 h-4"
                  taskUuid={taskUuid}
                  taskNanoId={taskNanoId}
                  withoutClose
                />
              </CheckPermissions>
            </Fragment>
          )}

          <CheckPermissions
            action={TasksPermissions.READ_TASK_DOWNLOAD_TASK_RESULTS_BUTTON}
          >
            <DownloadTaskResultsModalButton
              className="p-0.5 rounded inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              taskNanoId={taskNanoId}
              iconClassName="w-4 h-4"
            />
          </CheckPermissions>

          <DashboardTasksResultsListItemDropdownMenu
            taskNanoId={taskNanoId}
            taskSourceFilesCount={taskSourceFilesCount}
          />
        </div>

        {size(resultsFileAttachments) > 1 && (
          <Fragment>
            <div className="h-40 w-40 border border-white dark:border-gray-900 bg-gray-300 dark:bg-gray-800 rounded transform translate-y-3 scale-90 origin-bottom absolute top-0 left-0 z-0" />
            <div className="h-40 w-40 border border-white dark:border-gray-900 bg-gray-300 dark:bg-gray-800 rounded transform translate-y-1.5 scale-95 origin-bottom absolute top-0 left-0 z-0" />
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default DashboardTasksResultsListItem;
