import { ClassName } from '../../../../types';
import {
  ProjectTaskLinkProject,
  ProjectTaskLinkTask
} from './ProjectTaskLink.types';

import { NextPureLinkHelper } from '../../../../helpers/links/NextPureLinkHelper';

import { ProjectPath } from '../../../projects/ProjectPath';
import { TaskPath } from '../../../tasks/TaskPath';

interface ProjectTaskLinkProps {
  project?: ProjectTaskLinkProject;
  task?: ProjectTaskLinkTask;
  className?: ClassName;
}

function ProjectTaskLink({
  project,
  task,
  className = 'hover:underline font-medium'
}: ProjectTaskLinkProps) {
  const projectLinkData = project || task?.project;

  return (
    <>
      {projectLinkData && (
        <NextPureLinkHelper
          text={projectLinkData?.name}
          href={ProjectPath.show(projectLinkData?.nanoId)}
          className={className}
        />
      )}
      {projectLinkData && task && <span>/</span>}
      {task && (
        <NextPureLinkHelper
          text={task.name}
          href={TaskPath.show(task.nanoId)}
          className={className}
        />
      )}
    </>
  );
}

export default ProjectTaskLink;
