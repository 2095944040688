import { useCallback, useEffect, useMemo, useState } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import fromPairs from 'lodash/fromPairs';
import every from 'lodash/every';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import size from 'lodash/size';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import values from 'lodash/values';

import { ID } from '../../../types';
import { CheckedHashItem } from './useTableCheckable.types';

import { usePreviousValue } from '../usePreviousValue';

interface UseTableCheckableItem {
  id: ID | number;
}

interface UseTableCheckableProps<T extends UseTableCheckableItem> {
  items: T[];
  checkedIds?: ID[];
}

const EMPTY_HASH = {};

function useTableCheckable<T extends UseTableCheckableItem>({
  items,
  checkedIds = []
}: UseTableCheckableProps<T>) {
  const [checkedHash, setCheckedIds] = useState<CheckedHashItem>(
    isEmpty(checkedIds)
      ? EMPTY_HASH
      : fromPairs(checkedIds.map((id) => [id, true]))
  );

  const checkedAll =
    !isEmpty(items) && every(checkedHash) && size(checkedHash) === size(items);

  const checkedPartial = !checkedAll && !!find(values(checkedHash), (v) => v);

  const checkedItems = useMemo<T[]>(
    () => filter(items, (item) => checkedHash[item.id]),
    [checkedHash, items]
  );

  const handleSetCheckedGroupIds = useCallback<(itemIds: ID[]) => void>(
    (itemIds) => {
      if (every(itemIds, (itemId) => checkedHash[itemId])) {
        return setCheckedIds((prevState: CheckedHashItem) => ({
          ...prevState,
          ...fromPairs(itemIds.map((itemId) => [itemId, false]))
        }));
      }

      setCheckedIds((prevState: CheckedHashItem) => ({
        ...prevState,
        ...fromPairs(itemIds.map((itemId) => [itemId, true]))
      }));
    },
    [checkedHash]
  );

  const handleSetCheckedIds = useCallback(
    (itemId) => {
      if (isArray(itemId)) {
        return handleSetCheckedGroupIds(itemId);
      }

      setCheckedIds((prevState: CheckedHashItem) => ({
        ...prevState,
        [itemId]: !prevState[itemId]
      }));
    },
    [handleSetCheckedGroupIds]
  );

  const handleCheckAll = useCallback(
    () =>
      setCheckedIds((prevState: CheckedHashItem) => ({
        ...prevState,
        ...fromPairs(items.map((item) => [item.id, !checkedAll]))
      })),
    [checkedAll, items, setCheckedIds]
  );

  const handleUncheckAll = useCallback(
    () => setCheckedIds({}),
    [setCheckedIds]
  );

  const prevCheckedIds = usePreviousValue(checkedIds);

  const prevItems = usePreviousValue(items);

  useEffect(() => {
    if (
      !isEqual(sortBy(prevCheckedIds), sortBy(checkedIds)) ||
      !isEqual(sortBy(prevItems), sortBy(items))
    ) {
      setCheckedIds((prevState: CheckedHashItem) => ({
        ...prevState,
        ...fromPairs(checkedIds.map((id) => [id, true]))
      }));
    }
  }, [prevCheckedIds, checkedIds, setCheckedIds, prevItems, items]);

  useEffect(() => {
    if (!isEqual(sortBy(prevItems), sortBy(items))) {
      setCheckedIds((prevState: { [key: string]: boolean }) => {
        const ids = items.map((item) => `${item.id}`);
        const prevStateIds = keys(prevState);

        return fromPairs(
          compact(
            prevStateIds.map((prevStateId: string) =>
              includes(ids, prevStateId)
                ? [prevStateId, prevState[prevStateId]]
                : null
            )
          )
        );
      });
    }
  }, [items, prevItems, setCheckedIds]);

  return {
    checkedHash,
    checkedItems,
    checkedAll,
    checkedPartial,
    handleSetCheckedIds,
    handleCheckAll,
    handleUncheckAll
  };
}

export default useTableCheckable;
