import { TaskNanoID, TaskUUID } from '../../../tasksTypes';
import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, I18nText } from '../../../../../types';

import { useTrackTimeInTaskForm } from '../../forms/TrackTimeInTaskForm/hooks/useTrackTimeInTaskForm';

import { TrackTimeInTaskForm } from '../../forms/TrackTimeInTaskForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { tasksKeys, words } from '../../../../../locales/keys';

interface TrackTimeInTaskModalButtonProps {
  taskUuid: TaskUUID;
  taskNanoId?: TaskNanoID;
  className?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  iconClassName?: ClassName;
  tooltipI18nText?: I18nText;
  tooltipSingleton?: boolean;
}

const TRACK_TIME_IN_TASK_FORM = 'track-time-in-task-form';

function TrackTimeInTaskModalButton({
  taskUuid: uuid,
  taskNanoId,
  className,
  icon,
  i18nText,
  tooltipSingleton,
  tooltipI18nText,
  iconClassName
}: TrackTimeInTaskModalButtonProps) {
  const {
    registerFields,
    validationErrors,
    trackTimeInTaskLoading,
    trackTimeInTaskErrorMessage,
    trackTimeInTaskReset,
    resetTrackTimeInTaskForm,
    handleTrackTimeInTask
  } = useTrackTimeInTaskForm({
    uuid,
    taskNanoId
  });

  return (
    <FormModalButton
      className={
        className ||
        'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 hidden sm:flex'
      }
      form={TRACK_TIME_IN_TASK_FORM}
      i18nSubmitText={words.save}
      i18nTitle={tasksKeys.trackTaskTime}
      i18nText={i18nText}
      isLoading={trackTimeInTaskLoading}
      onOpen={resetTrackTimeInTaskForm}
      onClose={trackTimeInTaskReset}
      onSubmit={handleTrackTimeInTask}
      icon={icon}
      iconClassName={iconClassName || 'h-6 w-6'}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={TooltipPlacement.BOTTOM}
      tooltipSingleton={tooltipSingleton}
    >
      <TrackTimeInTaskForm
        form={TRACK_TIME_IN_TASK_FORM}
        isLoading={trackTimeInTaskLoading}
        registerBody={registerFields.registerBody}
        registerHours={registerFields.registerHours}
        registerMinutes={registerFields.registerMinutes}
        hoursValidationError={validationErrors.hoursValidationError}
        minutesValidationError={validationErrors.minutesValidationError}
      />

      <div className="px-4">
        <AlertMessage message={trackTimeInTaskErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default TrackTimeInTaskModalButton;
