import { gql } from 'graphql-request';

import {
  SmartContractCreatedAt,
  SmartContractID,
  SmartContractNanoID,
  SmartContractRuleRuleType,
  SmartContractRuleID,
  SmartContractRuleName,
  SmartContractStatus,
  SmartContractUpdatedAt,
  SmartContractUUID,
  SmartContractOpenedAt,
  SmartContractDoneAt,
  SmartContractCanceledAt,
  SmartContractRuleCompetencyContractorName,
  SmartContractRuleCompetencyContractorSurName,
  SmartContractDueDate,
  SmartContractItemID,
  SmartContractItemPrice,
  SmartContractItemInvoiceID,
  SmartContractItemInvoiceStatus,
  SmartContractItemInvoiceInvoiceType,
  SmartContractItemInvoiceAmount
} from '../smartContractsTypes';

export interface FetchTaskSmartContractsQueryResponse {
  id: SmartContractID;
  uuid: SmartContractUUID;
  nanoId: SmartContractNanoID;
  createdAt: SmartContractCreatedAt;
  updatedAt: SmartContractUpdatedAt;
  openedAt: SmartContractOpenedAt;
  doneAt: SmartContractDoneAt;
  dueDate: SmartContractDueDate;
  canceledAt: SmartContractCanceledAt;
  status: SmartContractStatus;
  smartContractRules: {
    id: SmartContractRuleID;
    name: SmartContractRuleName;
    ruleType: SmartContractRuleRuleType;
    competencyContractor: {
      name: SmartContractRuleCompetencyContractorName;
      surname: SmartContractRuleCompetencyContractorSurName;
    };
  }[];
  items: {
    id: SmartContractItemID;
    price: SmartContractItemPrice;
    invoices: {
      id: SmartContractItemInvoiceID;
      amount: SmartContractItemInvoiceAmount;
      status: SmartContractItemInvoiceStatus;
      invoiceType: SmartContractItemInvoiceInvoiceType;
    }[];
  }[];
}

export const FETCH_TASK_SMART_CONTRACTS_QUERY = gql`
  query TaskSmartContracts(
    $filters: SmartContractsFilters
    $sort: [SmartContractsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    smartContracts(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        nanoId
        createdAt
        updatedAt
        openedAt
        doneAt
        dueDate
        canceledAt
        status
        smartContractRules {
          id
          name
          ruleType
          competencyContractor {
            name
            surname
          }
        }
        items {
          id
          price
          invoices {
            id
            amount
            status
            invoiceType
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
