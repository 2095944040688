import { useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { TaskNanoID, TaskUUID } from '../../../tasksTypes';
import { ClassName } from '../../../../../types';

import {
  DownloadTaskResultsQueryResponse,
  DOWNLOAD_TASK_RESULTS_QUERY
} from '../../../queries/downloadTaskResults.query';

import { useAcceptResultsInTaskForm } from '../../forms/AcceptResultsInTaskForm/hooks/useAcceptResultsInTaskForm';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadTaskResults } from '../../../hooks/useDownloadTaskResults';

import { AcceptResultsInTaskForm } from '../../forms/AcceptResultsInTaskForm';
import { AcceptTasksResultsWarning } from '../AcceptTasksResultsModalButton/components/AcceptTasksResultsWarning';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';
import { Translate } from '../../../../../helpers/Translate';

import {
  resultsKeys,
  stringsKeys,
  tasksKeys,
  words
} from '../../../../../locales/keys';

interface AcceptTaskResultsModalButtonProps {
  taskNanoId: TaskNanoID;
  taskUuid: TaskUUID;
  withoutAccept?: boolean;
  withoutClose?: boolean;
  className?: ClassName;
  iconClassName?: ClassName;
}

const ACCEPT_RESULTS_IN_TASK_FORM = 'approve-results-in-task-form';

function AcceptTaskResultsModalButton({
  taskNanoId,
  taskUuid,
  withoutAccept,
  withoutClose,
  className,
  iconClassName = 'h-5 w-5'
}: AcceptTaskResultsModalButtonProps) {
  const { downloadNanoId } = useDownloadNanoId();

  const { downloadTaskResults, downloadTaskResultsErrorMessage } =
    useDownloadTaskResults<DownloadTaskResultsQueryResponse>({
      query: DOWNLOAD_TASK_RESULTS_QUERY
    });

  useShowToastOnErrorChange({ error: downloadTaskResultsErrorMessage });

  const handleDownloadTaskResults = useCallback<() => void>(
    () =>
      downloadTaskResults({
        uuid: taskNanoId,
        deviceNanoId: downloadNanoId
      }),
    [taskNanoId, downloadNanoId, downloadTaskResults]
  );

  const {
    control: acceptResultsControl,
    acceptResultsInTaskLoading,
    handleAcceptResultsInTask,
    validationErrors,
    isValid
  } = useAcceptResultsInTaskForm({
    taskUuid: taskUuid,
    taskNanoId: taskNanoId,
    withoutAccept,
    withoutClose
  });

  return (
    <SimpleModalButton
      className={className}
      buttonsContainerClassName="flex p-4 space-x-1 justify-between"
      submitClass="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white shadow-sm hover:shadow-md bg-green-500 hover:bg-green-600"
      i18nTextClassName="sr-only sm:not-sr-only"
      iconClassName={iconClassName}
      cancelButtonPosition="start"
      i18nSubmitText={tasksKeys.buttons.accept}
      i18nText={tasksKeys.buttons.accept}
      i18nTitle={stringsKeys.acceptTaskResult}
      i18nCloseText={words.cancel}
      i18nCancelText={resultsKeys.download}
      icon={IconsEnum.CHECK_OUTLINE}
      onSubmit={handleAcceptResultsInTask}
      onCancel={handleDownloadTaskResults}
      submitDisabled={!isValid}
      closeOnCancel={false}
      withCloseButton
      modalSize="sm"
    >
      <AcceptResultsInTaskForm
        form={ACCEPT_RESULTS_IN_TASK_FORM}
        control={acceptResultsControl}
        isLoading={acceptResultsInTaskLoading}
        markValidationError={validationErrors.markValidationError}
        isRFA
        acceptResultsWarning={
          <div className="flex-1 overflow-y-auto px-2 z-0">
            <AcceptTasksResultsWarning />
            <div className="text-center">
              <Translate id={stringsKeys.pleaseRateTaskRusult} />
            </div>
          </div>
        }
      />
      <div className="px-4">
        <AlertMessage message={validationErrors.markValidationError} />
      </div>
    </SimpleModalButton>
  );
}

export default AcceptTaskResultsModalButton;
