import React, { Fragment, useMemo } from 'react';
import addWeeks from 'date-fns/addWeeks';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';

import {
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Plugin
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { AccountNanoID } from '../../../../../../../../../../accounts/accountsTypes';
import { DashboardFinanceBalancesAccountTypeChartNumber } from '../../DashboardFinanceBalancesChart.types';

import {
  FETCH_ACCOUNT_BALANCES_QUERY,
  FetchAccountBalancesQueryResponse
} from '../../../../../../../../../../accountBalances/queries/fetchAccountBalances.query';

import { useFinPaginatedAccountBalances } from '../../../../../../../../../../accountBalances/hooks/useFinPaginatedAccountBalances';
import { useTranslate } from '../../../../../../../../../../../common/hooks/useTranslate';

import { Translate } from '../../../../../../../../../../../helpers/Translate';
import { MoneyHelper } from '../../../../../../../../../../../helpers/MoneyHelper';
import { LoadingSkeleton } from '../../../../../../../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../../../../../../../helpers/AlertMessage';

import { accountsKeys } from '../../../../../../../../../../../locales/keys';
import { AccountCache } from '../../../../../../../../../../accounts/AccountCache';

import { dateFnsConvert } from '../../../../../../../../../../../utils/dateFnsConvert';
import { getAccountBalancesData } from '../../utils/getAccountBalancesData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

type DataType = {
  x: string;
  y: number;
};

interface AccountBalancesChartProps {
  accountsNanoIds: AccountNanoID[];
  client: boolean;
}

const moneyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const plugin: Plugin<'line'> = {
  id: 'verticalLiner',
  beforeTooltipDraw: (chart, arg) => {
    const { ctx } = chart;
    const { top, bottom, width, height } = chart.chartArea;
    const { ticks } = chart.scales['x'];

    const x = chart.tooltip?.caretX;
    if (!x) return;

    arg.tooltip.y = 0;

    ctx.save();

    ctx.beginPath();
    ctx.strokeStyle = 'rgb(120, 120, 120)';
    ctx.moveTo(x, top);
    ctx.lineTo(x, bottom);
    ctx.stroke();

    const rectHalfWidth = width / ticks.length / 2;
    const isFirstTick = x - rectHalfWidth < rectHalfWidth;
    const isLastTick = x + rectHalfWidth > width;

    const rectX = isFirstTick || isFirstTick ? x : x - rectHalfWidth;
    const rectY = top;
    const rectWidth =
      isFirstTick || isLastTick ? rectHalfWidth : rectHalfWidth * 2;
    const rectHeight = height;

    ctx.fillStyle = 'rgb(210, 210, 210, 0.3)';
    ctx.fillRect(rectX, rectY, rectWidth, rectHeight);

    ctx.restore();
  }
};

const options: ChartOptions<'line'> = {
  responsive: true,
  animation: false,
  interaction: {
    mode: 'index' as const,
    intersect: false
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      titleFont: {
        size: 14
      },
      bodySpacing: 10,
      bodyFont: {
        size: 14
      },
      usePointStyle: true,
      callbacks: {
        label: (context) => {
          const label = context.dataset.label || '';
          const value = context.parsed.y || 0;
          return `${label}: ${moneyFormat.format(value)}`;
        },
        labelPointStyle: () => {
          return {
            pointStyle: 'circle',
            rotation: 0
          };
        }
      }
    }
  },
  scales: {
    y: {
      grid: {
        display: false
      },
      min: 0,
      ticks: {
        count: 6
      }
    }
  },
  maintainAspectRatio: false
};

function AccountBalancesChart({
  accountsNanoIds,
  client
}: AccountBalancesChartProps) {
  const { t } = useTranslate();

  const startDate = useMemo<Date>(() => addWeeks(new Date(), -2), []);

  const { accountBalances, accountBalancesError, accountBalancesFetched } =
    useFinPaginatedAccountBalances<FetchAccountBalancesQueryResponse>({
      cacheKey: AccountCache.companyAccountBalances(),
      query: FETCH_ACCOUNT_BALANCES_QUERY,
      initialFilters: {
        accountNanoId: {
          in: accountsNanoIds
        },
        createdAt: {
          gte: dateFnsConvert.toDate(startDate.toISOString())
        }
      },
      initialLimit: 1000
    });

  const dataset1 = useMemo<DataType[]>(
    () =>
      getAccountBalancesData({
        accountBalances,
        chartNumbers: client
          ? [
              DashboardFinanceBalancesAccountTypeChartNumber.CREDIT_1,
              DashboardFinanceBalancesAccountTypeChartNumber.CREDIT_2
            ]
          : [DashboardFinanceBalancesAccountTypeChartNumber.RECEIVABLE],
        startDate
      }),
    [accountBalances, client, startDate]
  );

  const dataset2 = useMemo<DataType[]>(
    () =>
      getAccountBalancesData({
        accountBalances,
        chartNumbers: [DashboardFinanceBalancesAccountTypeChartNumber.CASH],
        startDate
      }),
    [accountBalances, startDate]
  );

  const data = useMemo<ChartData<'line', DataType[]>>(
    () => ({
      datasets: [
        {
          label: t(client ? accountsKeys.credit : accountsKeys.receivable),
          data: dataset1,
          borderColor: 'rgb(245 158 11)',
          backgroundColor: 'rgb(245 158 11)',
          pointStyle: false,
          borderWidth: 3
        },
        {
          label: t(accountsKeys.cash),
          data: dataset2,
          borderColor: 'rgb(59 130 246)',
          backgroundColor: 'rgb(59 130 246)',
          pointStyle: false,
          borderWidth: 3
        }
      ]
    }),
    [client, dataset1, dataset2, t]
  );

  if (accountBalancesFetched && isEmpty(accountBalances)) {
    return null;
  }

  return (
    <Fragment>
      <AlertMessage addClassName="m-4" message={accountBalancesError} />

      <LoadingSkeleton loaded={accountBalancesFetched} addClass="h-80">
        <div className="flex justify-between items-start mb-2">
          <div className="flex items-center gap-2 text-md dark:text-gray-300">
            <Translate id={accountsKeys.balances} />
          </div>
        </div>

        <div className="flex justify-center gap-6 lg:gap-12 mb-2 lg:-mt-2">
          <div className="flex flex-col lg:flex-row-reverse items-center lg:gap-4">
            <div className="text-xl md:text-3xl font-medium">
              <MoneyHelper value={last(dataset1)?.y} />
            </div>
            <div className="flex items-center gap-2 lg:flex-col-reverse lg:gap-0.5">
              <div className="h-1 w-6 lg:w-10 rounded-full bg-yellow-500"></div>
              <div className="text-sm dark:text-gray-300">
                <Translate
                  id={client ? accountsKeys.credit : accountsKeys.receivable}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row-reverse items-center lg:gap-4">
            <div className="text-xl md:text-3xl font-medium">
              <MoneyHelper value={last(dataset2)?.y} />
            </div>
            <div className="flex items-center gap-2 lg:flex-col-reverse lg:gap-0.5">
              <div className="h-1 w-6 lg:w-10 rounded-full bg-blue-500"></div>
              <div className="text-sm dark:text-gray-300">
                <Translate id={accountsKeys.cash} />
              </div>
            </div>
          </div>
        </div>
        <div className="relative h-80">
          <Line data={data} options={options} plugins={[plugin]} />
        </div>
      </LoadingSkeleton>

      {/* <NoResults noResultsI18nText={words.noResultsYet} /> */}
    </Fragment>
  );
}

export default AccountBalancesChart;
