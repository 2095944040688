import { gql } from 'graphql-request';

import { MayBe } from '../../../types';

import {
  TaskID,
  TaskUUID,
  TaskDescription,
  TaskName,
  TaskStatus,
  TaskProjectID,
  TaskProjectUUID,
  TaskProjectName,
  TaskUserUUID,
  TaskUserFullName,
  TaskUserImageUUID,
  TaskUserImageFile,
  TaskBurning,
  TaskSetDate,
  TaskImplementationDate,
  TaskRequirements,
  TaskTagsUUID,
  TaskTagsLocalizedName,
  TaskMembersID,
  TaskMembersUUID,
  TaskMembersFullName,
  TaskMembersImageUUID,
  TaskMembersImageFile,
  TaskUserID,
  TaskAmount,
  TaskPrepayment,
  TaskDebt,
  TaskTaskAccount,
  TaskCreatedAt,
  TaskStartDate,
  TaskImplementedAt,
  TaskTagsID,
  TaskProjectUserID,
  TaskProjectUserUUID,
  TaskProjectUserFullName,
  TaskProjectUserImageUUID,
  TaskProjectUserImageFile,
  TaskVisibleForClient,
  TaskNotificationsEnabled,
  TaskPausedAt,
  TaskRevisionedAt,
  TaskMark,
  TaskSupportMark,
  TaskFavorite,
  TaskNanoID,
  TaskProjectNanoID,
  TaskMembersNanoID,
  TaskUserNanoID,
  TaskProjectUserNanoID,
  TaskSourceFilesCount,
  TaskProjectUserFinanceRoleName,
  TaskUserFinanceRoleName,
  TaskMembersFinanceRoleName,
  TaskProjectUserClient,
  TaskProjectUserNDA,
  TaskExpectedTime,
  TaskVisibleResultFileAttachmentsUUID,
  TaskMembersBlocked,
  TaskUserBlocked,
  TaskProjectUserBlocked,
  TaskProjectTeamName,
  TaskProjectTeamNanoID,
  TaskProjectTeamImageFile,
  TaskUserClient,
  TaskMembersClient,
  TaskUserCurrentTeamNanoID,
  TaskMembersCurrentTeamNanoID,
  TaskProjectUserCurrentTeamNanoID,
  TaskOwnerID,
  TaskOwnerUUID,
  TaskOwnerNanoID,
  TaskOwnerFullName,
  TaskOwnerFinanceRoleName,
  TaskOwnerBlocked,
  TaskOwnerClient,
  TaskOwnerCurrentTeamNanoID,
  TaskOwnerImageUUID,
  TaskOwnerImageFile,
  TaskResultsUploadedAt
} from '../tasksTypes';

export interface FetchShowTaskQueryResponse {
  id: TaskID;
  uuid: TaskUUID;
  nanoId: TaskNanoID;
  name: TaskName;
  description: TaskDescription;
  createdAt: TaskCreatedAt;
  status: TaskStatus;
  burning: TaskBurning;
  setDate: TaskSetDate;
  startDate: TaskStartDate;
  expectedTime: TaskExpectedTime;
  implementationDate: TaskImplementationDate;
  implementedAt: TaskImplementedAt;
  requirements: TaskRequirements;
  amount: TaskAmount;
  prepayment: TaskPrepayment;
  debt: TaskDebt;
  taskAccount: TaskTaskAccount;
  visibleForClient: TaskVisibleForClient;
  notificationsEnabled: TaskNotificationsEnabled;
  pausedAt: TaskPausedAt;
  revisionedAt: TaskRevisionedAt;
  mark: TaskMark;
  supportMark: TaskSupportMark;
  favorite: TaskFavorite;
  sourceFilesCount: TaskSourceFilesCount;
  resultsUploadedAt: TaskResultsUploadedAt;
  resultFileAttachments: {
    uuid: TaskVisibleResultFileAttachmentsUUID;
  }[];
  project: {
    id: TaskProjectID;
    uuid: TaskProjectUUID;
    nanoId: TaskProjectNanoID;
    name: TaskProjectName;
    user: {
      id: TaskProjectUserID;
      uuid: TaskProjectUserUUID;
      nanoId: TaskProjectUserNanoID;
      fullName: TaskProjectUserFullName;
      financeRoleName: TaskProjectUserFinanceRoleName;
      client: TaskProjectUserClient;
      nda: TaskProjectUserNDA;
      blocked: TaskProjectUserBlocked;
      currentTeam: MayBe<{
        nanoId: TaskProjectUserCurrentTeamNanoID;
      }>;
      image: {
        uuid: TaskProjectUserImageUUID;
        file: TaskProjectUserImageFile;
      };
    };
    team: {
      name: TaskProjectTeamName;
      nanoId: TaskProjectTeamNanoID;
      image: {
        file: TaskProjectTeamImageFile;
      };
    };
  };
  user: {
    id: TaskUserID;
    uuid: TaskUserUUID;
    nanoId: TaskUserNanoID;
    fullName: TaskUserFullName;
    financeRoleName: TaskUserFinanceRoleName;
    blocked: TaskUserBlocked;
    client: TaskUserClient;
    currentTeam: MayBe<{
      nanoId: TaskUserCurrentTeamNanoID;
    }>;
    image: {
      uuid: TaskUserImageUUID;
      file: TaskUserImageFile;
    };
  };
  owner: {
    id: TaskOwnerID;
    uuid: TaskOwnerUUID;
    nanoId: TaskOwnerNanoID;
    fullName: TaskOwnerFullName;
    financeRoleName: TaskOwnerFinanceRoleName;
    blocked: TaskOwnerBlocked;
    client: TaskOwnerClient;
    currentTeam: MayBe<{
      nanoId: TaskOwnerCurrentTeamNanoID;
    }>;
    image: {
      uuid: TaskOwnerImageUUID;
      file: TaskOwnerImageFile;
    };
  };
  members: {
    id: TaskMembersID;
    uuid: TaskMembersUUID;
    nanoId: TaskMembersNanoID;
    fullName: TaskMembersFullName;
    financeRoleName: TaskMembersFinanceRoleName;
    blocked: TaskMembersBlocked;
    client: TaskMembersClient;
    currentTeam: MayBe<{
      nanoId: TaskMembersCurrentTeamNanoID;
    }>;
    image: {
      uuid: TaskMembersImageUUID;
      file: TaskMembersImageFile;
    };
  }[];
  tags: {
    id: TaskTagsID;
    uuid: TaskTagsUUID;
    localizedName: TaskTagsLocalizedName;
  }[];
}

export const FETCH_SHOW_TASK_QUERY = gql`
  query ShowTask($uuid: ID!) {
    task(uuid: $uuid) {
      id
      uuid
      nanoId
      name
      description
      status
      burning
      createdAt
      setDate
      implementationDate
      implementedAt
      startDate
      expectedTime
      requirements
      amount
      prepayment
      debt
      taskAccount
      visibleForClient
      notificationsEnabled
      pausedAt
      revisionedAt
      mark
      supportMark
      favorite
      sourceFilesCount
      resultsUploadedAt
      resultFileAttachments {
        uuid
      }
      project {
        id
        uuid
        nanoId
        name
        user {
          id
          uuid
          nanoId
          fullName
          financeRoleName
          client
          nda
          blocked
          currentTeam {
            nanoId
          }
          image {
            uuid
            file
          }
        }
        team {
          name
          nanoId
          image {
            file
          }
        }
      }
      user {
        id
        uuid
        nanoId
        fullName
        financeRoleName
        blocked
        client
        currentTeam {
          nanoId
        }
        image {
          uuid
          file
        }
      }
      owner {
        id
        uuid
        nanoId
        fullName
        financeRoleName
        blocked
        client
        currentTeam {
          nanoId
        }
        image {
          uuid
          file
        }
      }
      members {
        id
        uuid
        nanoId
        fullName
        financeRoleName
        blocked
        client
        currentTeam {
          nanoId
        }
        image {
          uuid
          file
        }
      }
      tags {
        id
        uuid
        localizedName
      }
    }
  }
`;
