import React from 'react';

import {
  ErrorMessage,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';
import {
  DropzoneHelperFileIds,
  DropzoneHelperFiles
} from '../../../../../helpers/dropzone/DropzoneHelper';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import {
  DropzoneField,
  DropzoneFieldRequiredProps
} from '../../../../../helpers/FormFields/DropzoneField';

import { TaskResultsFields } from '../../../tasksTypes';

import { SubmitResultsToTaskFormData } from './SubmitResultsToTaskForm.types';
import { TextAreaField } from '../../../../../helpers/FormFields/TextAreaField';

import { formsFields } from '../../../../../locales/keys';

interface SubmitResultsToTaskFormProps {
  form: string;
  isLoading?: IsLoading;
  registerHours: RegisterFormFieldType<HTMLInputElement>;
  registerMinutes: RegisterFormFieldType<HTMLInputElement>;
  registerBody: RegisterFormFieldType<HTMLTextAreaElement>;
  initialFiles?: DropzoneHelperFiles;
  initialFileIds?: DropzoneHelperFileIds;
  fileAttachmentIdsValidationError: ErrorMessage;
  hoursValidationError: ErrorMessage;
  minutesValidationError: ErrorMessage;
  onChangeFiles?: (files: DropzoneHelperFiles) => void;
}

function SubmitResultsToTaskForm({
  form,
  control,
  isLoading,
  registerHours,
  registerMinutes,
  registerBody,
  fileAttachmentIdsValidationError,
  hoursValidationError,
  minutesValidationError,
  initialFiles,
  initialFileIds,
  onChangeFiles
}: SubmitResultsToTaskFormProps &
  DropzoneFieldRequiredProps<SubmitResultsToTaskFormData>) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <DropzoneField
          withoutTabs
          control={control}
          name={TaskResultsFields.FILE_ATTACHMENT_IDS}
          error={fileAttachmentIdsValidationError}
          type="fileAttachments"
          labelFor="submit-task-results-files"
          disabled={isLoading}
          initialFiles={initialFiles}
          initialFileIds={initialFileIds}
          onChangeFiles={onChangeFiles}
        />
      </div>
      <div className="p-4">
        <div className="flex flex-col gap-4">
          <div className="flex gap-3 flex-wrap">
            <div className="w-full sm:w-auto sm:flex-1">
              <TextAreaField
                name={registerBody.name}
                ref={registerBody.ref}
                onChange={registerBody.onChange}
                i18nLabel={formsFields.description}
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                disabled={isLoading}
                rows={5}
              />
            </div>
          </div>
          <div className="flex gap-3 flex-wrap">
            <div className="w-full sm:w-auto sm:flex-1">
              <InputField
                name={registerHours.name}
                onChange={registerHours.onChange}
                ref={registerHours.ref}
                i18nLabel={formsFields.hours}
                placeholder="0-7"
                inputWrapperClassName="relative mt-1"
                error={hoursValidationError}
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                inputClassName="basic-input number-appearance-none"
                showErrorIcon={false}
                disabled={isLoading}
                type="number"
              />
            </div>
            <div className="w-full sm:w-auto sm:flex-1">
              <InputField
                name={registerMinutes.name}
                onChange={registerMinutes.onChange}
                ref={registerMinutes.ref}
                i18nLabel={formsFields.minutes}
                placeholder="0-59"
                error={minutesValidationError}
                inputWrapperClassName="relative mt-1"
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                inputClassName="basic-input number-appearance-none"
                showErrorIcon={false}
                disabled={isLoading}
                type="number"
              />
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default SubmitResultsToTaskForm;
