import React from 'react';

import some from 'lodash/some';

import {
  TaskNanoID,
  TaskStatuses
} from '../../../../../../../../tasks/tasksTypes';

import {
  FETCH_SHOW_TASK_QUERY,
  FetchShowTaskQueryResponse
} from '../../../../../../../../tasks/queries/fetchShowTask.query';
import { FetchTaskMessagesQueryResponse } from '../../../../../../../../messages/queries/fetchTaskMessages.query';

import { useTask } from '../../../../../../../../tasks/hooks/useTask';
import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';
import { useTaskMessagesContentCreateMessage } from '../../../../../../../../tasks/components/content/TaskMessagesContent/hooks/useTaskMessagesContentCreateMessage';

import { TaskMessagesContentMessagesList } from '../../../../../../../../tasks/components/content/TaskMessagesContent/components/TaskMessagesContentMessagesList';
import { DashboardActiveTaskMessageHeader } from './components/DashboardActiveTaskMessageHeader';
import { ItemCreateMessageForm } from '../../../../../../../../messages/components/forms/ItemCreateMessageForm';

import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';

import { TaskCache } from '../../../../../../../../tasks/TaskCache';

import { TasksPermissions } from '../../../../../../../../tasks/tasksConstants';

interface DashboardActiveTaskMessagesProps {
  taskNanoId?: TaskNanoID;
}

function DashboardActiveTaskMessages({
  taskNanoId
}: DashboardActiveTaskMessagesProps) {
  const currentUser = useCurrentUser();

  const { task, taskError, taskFetched, taskIsPlaceholderData } =
    useTask<FetchShowTaskQueryResponse>({
      tasks: [],
      cacheKey: TaskCache.activeTaskCacheKey(taskNanoId),
      query: FETCH_SHOW_TASK_QUERY,
      uuid: taskNanoId,
      options: {
        enabled: !!taskNanoId,
        enabledPlaceholder: !!taskNanoId
      }
    });

  const {
    showClosedTaskMessageForm,
    sendingMessages,
    handleCreateMessageInTask,
    handleFetchMessagesSuccess,
    handleRemoveSendingMessage
  } = useTaskMessagesContentCreateMessage<FetchTaskMessagesQueryResponse>({
    task
  });

  const isTaskMember = some(task?.members, { uuid: currentUser.uuid });

  const isTaskClosed =
    task &&
    (task.status === TaskStatuses.DONE ||
      task.status === TaskStatuses.CANCELED);

  return (
    <div className="h-full flex-1 border-l-4 border-double dark:border-gray-800">
      {taskNanoId ? (
        <div className="h-full flex flex-col">
          <AlertMessage addClassName="mt-4" message={taskError} />
          <LoadingSkeleton loaded={taskFetched || taskIsPlaceholderData}>
            <DashboardActiveTaskMessageHeader
              task={task}
              isTaskMember={isTaskMember}
            />

            {task && task.project ? (
              <TaskMessagesContentMessagesList
                taskNanoId={task.nanoId}
                projectUuid={task.project.uuid}
                taskUuid={task.uuid}
                sendingMessages={sendingMessages}
                onFetchMessagesSuccess={handleFetchMessagesSuccess}
                onRemoveSendingMessage={handleRemoveSendingMessage}
                withoutPinnedMessages
              />
            ) : null}

            {(task && task.project && !isTaskClosed) ||
            (task &&
              task.project &&
              isTaskClosed &&
              showClosedTaskMessageForm) ? (
              <ItemCreateMessageForm
                projectNanoId={task.project.nanoId}
                withFullscreenDropzone={currentUser.hasPermissions(
                  TasksPermissions.READ_TASK_CREATE_MESSAGE_FORM_FULLSCREEN_DROPZONE
                )}
                withClientLocalTime={currentUser.hasPermissions(
                  TasksPermissions.READ_TASK_CLIENT_LOCAL_TIME
                )}
                withPasteFiles={currentUser.hasPermissions(
                  TasksPermissions.READ_TASK_CREATE_MESSAGE_FORM_PASTE_FILES
                )}
                onSubmit={handleCreateMessageInTask}
                teamNanoId={task.project.team?.nanoId}
              />
            ) : null}
          </LoadingSkeleton>
        </div>
      ) : null}
    </div>
  );
}

export default DashboardActiveTaskMessages;
