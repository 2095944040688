import {
  CreateItemCacheKeys,
  DefaultAt,
  DeleteItemCacheKeys,
  FetchItemFetched,
  FetchItemsCacheKey,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSerializer,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  NanoID,
  NanoIDFilter,
  UUID
} from '../../types';
import { UpdateItemsCacheKeys } from '../../types/updateItemsTypes';
import { CompetencyID } from '../competencies/competenciesTypes';

export type UserCompetencyID = ID;
export type UserCompetencyUUID = UUID;
export type UserCompetencyNanoID = NanoID;
export type UserCompetencyPresenceType = UserCompetencyPresenceTypes;
export type UserCompetencyValue = number;
export type UserCompetencyLabel = string;

export type UserCompetencyDefaultAt = DefaultAt;

export type UserCompetencyCompetencyID = CompetencyID;
export type UserCompetencyCompetencyName = string;

export const enum UserCompetencyPresenceTypes {
  ENABLED = 'enabled',
  DRAFT = 'draft',
  TRASH = 'trash'
}

export const enum FetchUserCompetenciesSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export interface FetchUserCompetenciesFilters {
  userNanoId?: NanoIDFilter;
}

export type UpdateUserCompetenciesCacheKeys = UpdateItemsCacheKeys;

export type CreateUserCompetencyCacheKeys = CreateItemCacheKeys;

export type DeleteUserCompetencyCacheKeys = DeleteItemCacheKeys;

export type FetchUserCompetenciesCacheKey = FetchItemsCacheKey;

export type FetchUserCompetenciesSort = FetchItemsSort;
export type FetchUserCompetenciesSortUserCompetencies = FetchItemsSortItems;
export type FetchUserCompetenciesPage = FetchItemsPage;
export type FetchUserCompetenciesLimit = FetchItemsLimit;
export type FetchUserCompetenciesSerializer = FetchItemsSerializer;
export type FetchUserCompetenciesErrorMessage = FetchItemsErrorMessage;
export type FetchUserCompetenciesFetched = FetchItemsFetched;
export type FetchSmartContractFetched = FetchItemFetched;
export type FetchUserCompetenciesIsPlaceholderData =
  FetchItemsIsPlaceholderData;
// export type FetchSmartContractIsPlaceholderData = FetchItemIsPlaceholderData;
export type FetchUserCompetenciesPaginateUserCompetencies =
  FetchItemsPaginateItems;
export type FetchUserCompetenciesPrefetchUserCompetencies =
  FetchItemsPrefetchItems;
export type FetchUserCompetenciesTotalCount = FetchItemsTotalCount;
export type FetchUserCompetenciesFilterUserCompetencies =
  FetchItemsFilterItems<FetchUserCompetenciesFilters>;

export type ChangeUserCompetenciesFiltersFunc = (
  changedFilters: FetchUserCompetenciesFilterUserCompetencies,
  removeFilters?: string[]
) => void;

export const enum UserCompetencyFields {
  COMPETENCY_ID = 'competencyId',
  LABEL = 'label',
  VALUE = 'value'
}
