import React, { useEffect } from 'react';

import {
  TaskNanoID,
  TaskProjectUUID,
  TaskUUID
} from '../../../../../tasksTypes';
import { InfiniteIndexQueryOnSuccess } from '../../../../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

import { useMessages } from '../../../../../../messages/hooks/useMessages';
import { usePreviousValue } from '../../../../../../../common/hooks/usePreviousValue';

import {
  FETCH_TASK_MESSAGES,
  FetchTaskMessagesQueryResponse
} from '../../../../../../messages/queries/fetchTaskMessages.query';

import {
  ItemMessagesList,
  ItemMessagesListMessages,
  ItemMessagesListOnRemoveSendingMessage
} from '../../../../../../messages/components/list/ItemMessagesList';
import { ItemMessagesHeader } from '../../../../../../messages/components/headers/ItemMessagesHeader';

import { TaskCache } from '../../../../../TaskCache';
import { MessagesPermissions } from '../../../../../../messages/messagesConstants';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { useFinUsers } from '../../../../../../users/hooks/useFinUsers';
import {
  FETCH_USERS_MESSAGES_AUTHORS_QUERY,
  FetchUsersMessagesAuthorsQueryResponse
} from '../../../../../../users/queries/fetchUsersMessagesAuthors.query';
import { UserCache } from '../../../../../../users/UserCache';

interface ItemMessagesListProps {
  taskNanoId: TaskNanoID;
  projectUuid: TaskProjectUUID;
  taskUuid: TaskUUID;
  sendingMessages: ItemMessagesListMessages;
  onFetchMessagesSuccess: InfiniteIndexQueryOnSuccess<FetchTaskMessagesQueryResponse>;
  onRemoveSendingMessage: ItemMessagesListOnRemoveSendingMessage;
  withoutPinnedMessages?: boolean;
}

const taskPinnedMessagesLimit = 100;

function TaskMessagesContentMessagesList({
  taskNanoId,
  projectUuid,
  taskUuid,
  sendingMessages,
  onFetchMessagesSuccess,
  onRemoveSendingMessage,
  withoutPinnedMessages
}: ItemMessagesListProps) {
  const {
    messages,
    messagesError,
    messagesFetched,
    messagesFetchingNextPage,
    messagesIsPlaceholderData,
    hasNextMessagesPage,
    loadMoreMessages,
    changeMessagesFilters,
    messagesFilters,
    filterMessages
  } = useMessages<FetchTaskMessagesQueryResponse>({
    cacheKey: TaskCache.messagesCacheKey(taskNanoId),
    query: FETCH_TASK_MESSAGES,
    initialFilters: {
      taskNanoId
    },
    options: { onSuccess: onFetchMessagesSuccess }
  });

  const {
    messages: pinnedMessages,
    messagesError: pinnedMessagesError,
    messagesFetched: pinnedMessagesFetched,
    messagesTotalCount: pinnedMessagesTotalCount,
    changeMessagesFilters: changePinnedMessagesFilters
  } = useMessages<FetchTaskMessagesQueryResponse>({
    cacheKey: TaskCache.pinnedMessagesCacheKey(taskNanoId),
    query: FETCH_TASK_MESSAGES,
    initialLimit: taskPinnedMessagesLimit,
    initialFilters: {
      taskNanoId,
      pinned: true
    },
    options: {
      enabled: !withoutPinnedMessages,
      enabledPlaceholder: !withoutPinnedMessages
    }
  });

  const previousValue = usePreviousValue(taskNanoId);

  useEffect(() => {
    if (previousValue && taskNanoId !== previousValue) {
      changeMessagesFilters({
        taskNanoId
      });
      !withoutPinnedMessages &&
        changePinnedMessagesFilters({
          taskNanoId,
          pinned: true
        });
    }
  }, [
    changeMessagesFilters,
    changePinnedMessagesFilters,
    previousValue,
    taskNanoId,
    withoutPinnedMessages
  ]);

  const { users, usersError, usersFetched, usersTotalCount } =
    useFinUsers<FetchUsersMessagesAuthorsQueryResponse>({
      cacheKey: UserCache.usersMessagesByTask(taskNanoId),
      query: FETCH_USERS_MESSAGES_AUTHORS_QUERY,
      initialFilters: {
        messagesInTaskNanoId: { eq: taskNanoId }
      },
      initialLimit: 100
    });

  return (
    <>
      <CheckPermissions action={MessagesPermissions.READ_MESSAGES_HEADER}>
        <ItemMessagesHeader
          pinnedMessages={pinnedMessages}
          pinnedMessagesCacheKey={TaskCache.pinnedMessagesCacheKey(taskNanoId)}
          pinnedMessagesTotalCount={pinnedMessagesTotalCount}
          forwardMessageProjectUuid={projectUuid}
          forwardMessageTaskUuid={taskUuid}
          messagesFilters={messagesFilters}
          filterMessages={filterMessages}
          users={users}
          usersError={usersError}
          usersFetched={usersFetched}
          usersTotalCount={usersTotalCount}
        />
      </CheckPermissions>
      <ItemMessagesList
        messages={messages}
        messagesCacheKey={TaskCache.messagesCacheKey(taskNanoId)}
        messagesErrorMessage={messagesError}
        messagesFetched={messagesFetched}
        messagesFetchingNextPage={messagesFetchingNextPage}
        hasNextMessagesPage={hasNextMessagesPage}
        loadMoreMessages={loadMoreMessages}
        messagesIsPlaceholderData={messagesIsPlaceholderData}
        pinnedMessages={withoutPinnedMessages ? [] : pinnedMessages}
        pinnedMessagesCacheKey={TaskCache.pinnedMessagesCacheKey(taskNanoId)}
        pinnedMessagesFetched={pinnedMessagesFetched}
        pinnedMessagesErrorMessage={pinnedMessagesError}
        pinnedMessagesTotalCount={pinnedMessagesTotalCount}
        forwardMessageProjectUuid={projectUuid}
        forwardMessageTaskUuid={taskUuid}
        sendingMessages={sendingMessages}
        onRemoveSendingMessage={onRemoveSendingMessage}
      />
    </>
  );
}

export default TaskMessagesContentMessagesList;
