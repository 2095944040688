import React from 'react';

import {
  ProjectName,
  ProjectNanoID,
  ProjectStatus,
  ProjectTasksAmountsSum,
  ProjectTasksPrepaymentsSum,
  ProjectUUID
} from '../../../../../../../../../../projects/projectsTypes';

import { NextPureLinkHelper } from '../../../../../../../../../../../helpers/links/NextPureLinkHelper';
import { Status } from '../../../../../../../../../../../helpers/Status';
import { Progress } from '../../../../../../../../../../../helpers/Progress';
import { MoneyHelper } from '../../../../../../../../../../../helpers/MoneyHelper';

import { ProjectPath } from '../../../../../../../../../../projects/ProjectPath';

interface DashboardActiveTaskProjectMessagesHeaderProject {
  uuid: ProjectUUID;
  name: ProjectName;
  nanoId: ProjectNanoID;
  status: ProjectStatus;
  tasksAmountsSum: ProjectTasksAmountsSum;
  tasksPrepaymentsSum: ProjectTasksPrepaymentsSum;
}

interface DashboardActiveTaskProjectMessagesHeaderProps {
  project: DashboardActiveTaskProjectMessagesHeaderProject;
}

function DashboardActiveTaskProjectMessagesHeader({
  project
}: DashboardActiveTaskProjectMessagesHeaderProps) {
  if (!project) {
    return null;
  }

  return (
    <div className="px-2 pb-2 pt-1 flex items-center border-b dark:border-gray-800">
      <div className="flex-1">
        <NextPureLinkHelper
          text={project.name}
          href={ProjectPath.show(project.nanoId)}
          className="text-sm mb-1 hover:underline"
        />

        <div className="flex gap-2">
          <Status status={project.status} />

          {
            <div className="w-32">
              <div className="flex gap-2 items-center leading-tight">
                <div className="text-gray-900 dark:text-gray-200 font-normal text-sm">
                  <span className="mt-1">
                    <MoneyHelper value={project.tasksAmountsSum} />
                  </span>
                </div>
                {project.tasksAmountsSum !== 0 && (
                  <div className="text-xs text-gray-500 whitespace-nowrap">
                    <span>
                      <MoneyHelper value={project.tasksPrepaymentsSum} />
                    </span>
                  </div>
                )}
              </div>

              <Progress
                min={project.tasksPrepaymentsSum}
                max={project.tasksAmountsSum}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default DashboardActiveTaskProjectMessagesHeader;
