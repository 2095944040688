import { gql } from 'graphql-request';

import { TeamID, TeamNanoID, TeamRevenue, TeamUUID } from '../teamsTypes';

export interface FetchTeamRevenueQueryResponse {
  id: TeamID;
  uuid: TeamUUID;
  nanoId: TeamNanoID;
  revenue: TeamRevenue;
}

export const FETCH_TEAM_REVENUE_QUERY = gql`
  query TeamRevenue($uuid: ID!) {
    team(uuid: $uuid) {
      id
      uuid
      nanoId
      revenue
    }
  }
`;
