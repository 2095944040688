import React, { useCallback } from 'react';
import find from 'lodash/find';
import isArray from 'lodash/isArray';

import { DashboardTasksItemType } from '../DashboardTasksItem/DashboardTasksItem.types';
import { MultiSelectChangeCallbackType } from '../../../../../../../../../helpers/MultiSelect/types';

import { MultiSelect } from '../../../../../../../../../helpers/MultiSelect';
import { Translate } from '../../../../../../../../../helpers/Translate';

import { words } from '../../../../../../../../../locales/keys';

const options = [
  {
    value: DashboardTasksItemType.DAY,
    label: <Translate id={words.oneDay} />
  },
  {
    value: DashboardTasksItemType.WEEK,
    label: <Translate id={words.oneWeek} />
  },
  {
    value: DashboardTasksItemType.MONTH,
    label: <Translate id={words.oneMonth} />
  }
];

interface DashboardTasksItemSelectTypeProps {
  defaultValue: DashboardTasksItemType;
  onChange: (value: DashboardTasksItemType) => void;
}

function DashboardTasksItemSelectType({
  defaultValue,
  onChange
}: DashboardTasksItemSelectTypeProps) {
  const handleChange = useCallback<MultiSelectChangeCallbackType>(
    (selectOption) => {
      onChange(
        isArray(selectOption)
          ? null
          : (selectOption.value as DashboardTasksItemType)
      );
    },
    [onChange]
  );

  return (
    <MultiSelect
      data={options}
      multi={false}
      onChange={handleChange}
      value={find(options, ['value', defaultValue])}
      classNamePrefix="av_select"
      inputWrapperClassName="w-28 text-sm font-normal"
    />
  );
}

export default DashboardTasksItemSelectType;
