import {
  UserCompetencyUUID,
  FetchUserCompetenciesFilters,
  FetchUserCompetenciesSort,
  FetchUserCompetenciesPage,
  FetchUserCompetenciesLimit,
  UserCompetencyID,
  FetchUserCompetenciesSortTypes,
  FetchUserCompetenciesCacheKey,
  UserCompetencyPresenceType,
  UserCompetencyValue,
  UserCompetencyCompetencyName,
  UserCompetencyLabel,
  UserCompetencyNanoID,
  UserCompetencyDefaultAt,
  UserCompetencyCompetencyID
} from '../../userCompetenciesTypes';

import { useBffIndexQuery } from '../../../common/hooks/base/reactQuery/useBffIndexQuery';

import { UserCompetencyBffUrl } from '../../UserCompetencyBffUrl';

export const INITIAL_USER_COMPETENCIES_FILTERS = {};
export const INITIAL_USER_COMPETENCIES_PAGE = 1;
export const INITIAL_USER_COMPETENCIES_SORT = [
  FetchUserCompetenciesSortTypes.CREATED_AT_DESC
];
export const INITIAL_USER_COMPETENCIES_LIMIT = 100;

interface UserCompetenciesUserCompetencyCompetency {
  id: UserCompetencyCompetencyID;
  name: UserCompetencyCompetencyName;
}

interface FetchUserCompetenciesUserCompetencyType {
  id: UserCompetencyID;
  uuid: UserCompetencyUUID;
  nanoId: UserCompetencyNanoID;
  presenceType: UserCompetencyPresenceType;
  value: UserCompetencyValue;
  label: UserCompetencyLabel;
  defaultAt: UserCompetencyDefaultAt;
  competency: UserCompetenciesUserCompetencyCompetency;
}

interface UserCompetenciesOptions {
  cacheKey: FetchUserCompetenciesCacheKey;
  initialFilters?: FetchUserCompetenciesFilters;
  initialSort?: FetchUserCompetenciesSort;
  initialPage?: FetchUserCompetenciesPage;
  initialLimit?: FetchUserCompetenciesLimit;
}

const scope = 'userCompetencies';

function usePaginatedUserCompetencies({
  cacheKey,
  initialFilters = INITIAL_USER_COMPETENCIES_FILTERS,
  initialSort = INITIAL_USER_COMPETENCIES_SORT,
  initialPage = INITIAL_USER_COMPETENCIES_PAGE,
  initialLimit = INITIAL_USER_COMPETENCIES_LIMIT
}: UserCompetenciesOptions) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
    // prefetchItem
  } = useBffIndexQuery<FetchUserCompetenciesUserCompetencyType>({
    cacheKey,
    scope,
    url: UserCompetencyBffUrl.index(),
    initialFilters,
    initialLimit,
    initialPage,
    initialSort
  });

  return {
    userCompetenciesData: data,
    userCompetencies: items,
    userCompetenciesError: itemsError,
    userCompetenciesErrorMessage: itemsErrorMessage,
    userCompetenciesTotalCount: itemsTotalCount,
    userCompetenciesFetched: isFetched,
    userCompetenciesLoading: isLoading,
    userCompetenciesIsPlaceholderData: isPlaceholderData,
    userCompetenciesFilters: currentFilters,
    userCompetenciesSort: currentSort,
    userCompetenciesPage: currentPage,
    userCompetenciesLimit: currentLimit,
    updateUserCompetencyCache: updateItemCache,
    filterUserCompetencies: filterItems,
    changeUserCompetenciesFilters: changeItemsFilters,
    clearUserCompetenciesFilters: clearItemsFilters,
    sortUserCompetencies: sortItems,
    paginateUserCompetencies: paginateItems,
    limitUserCompetencies: limitItems,
    prefetchUserCompetencies: prefetchItems
    // prefetchUserCompetency: prefetchItem
  };
}

export default usePaginatedUserCompetencies;
