import {
  FetchInvoicesCacheKey,
  FetchFinInvoicesFilters,
  FetchInvoicesGqlQuery,
  FetchInvoicesLimit,
  FetchInvoicesPage,
  FetchInvoicesSort
} from '../../invoicesTypes';
import { MoneyType } from '../../../../types';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_INVOICES_FILTERS,
  INITIAL_INVOICES_LIMIT,
  INITIAL_INVOICES_PAGE,
  INITIAL_INVOICES_SORT
} from '../../invoicesConstants';

type PaginatedInvoicesAdditionalParams = {
  withTotalAmount?: boolean;
};

type PaginatedInvoicesMetaType = {
  totalAmount?: MoneyType;
};

interface PaginatedInvoicesOptions {
  additionalParams?: PaginatedInvoicesAdditionalParams;
  cacheKey: FetchInvoicesCacheKey;
  initialFilters?: FetchFinInvoicesFilters;
  initialSort?: FetchInvoicesSort;
  initialPage?: FetchInvoicesPage;
  initialLimit?: FetchInvoicesLimit;
  options?: {
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
  query: FetchInvoicesGqlQuery;
}

const scope = 'invoices';

function useFinPaginatedInvoices<FetchInvoicesInvoiceType>({
  additionalParams,
  cacheKey,
  initialFilters = INITIAL_INVOICES_FILTERS,
  initialSort = INITIAL_INVOICES_SORT,
  initialPage = INITIAL_INVOICES_PAGE,
  initialLimit = INITIAL_INVOICES_LIMIT,
  options,
  query
}: PaginatedInvoicesOptions) {
  const {
    changeItemsFilters,
    clearItemsFilters,
    currentFilters,
    currentLimit,
    currentPage,
    currentSort,
    data,
    filterItems,
    isFetched,
    isLoading,
    isPlaceholderData,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    limitItems,
    meta,
    paginateItems,
    prefetchItems,
    sortItems,
    updateItemCache
  } = useFinIndexQuery<FetchInvoicesInvoiceType, PaginatedInvoicesMetaType>({
    additionalParams,
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    scope,
    query,
    options
  });

  return {
    changeInvoicesFilters: changeItemsFilters,
    clearInvoicesFilters: clearItemsFilters,
    filterInvoices: filterItems,
    invoices: items,
    invoicesData: data,
    invoicesError: itemsError,
    invoicesErrorMessage: itemsErrorMessage,
    invoicesFetched: isFetched,
    invoicesFilters: currentFilters,
    invoicesIsPlaceholderData: isPlaceholderData,
    invoicesLimit: currentLimit,
    invoicesLoading: isLoading,
    invoicesMeta: meta,
    invoicesPage: currentPage,
    invoicesSort: currentSort,
    invoicesTotalCount: itemsTotalCount,
    limitInvoices: limitItems,
    paginateInvoices: paginateItems,
    prefetchInvoices: prefetchItems,
    sortInvoices: sortItems,
    updateInvoiceCache: updateItemCache
  };
}

export default useFinPaginatedInvoices;
