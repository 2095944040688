import React, { useState } from 'react';
import find from 'lodash/find';
import isArray from 'lodash/isArray';

import { TeamNanoID } from '../../../../../../../../teams/teamsTypes';
import {
  FetchInvoicesSortTypes,
  InvoiceStatuses,
  InvoiceTypes
} from '../../../../../../../../invoices/invoicesTypes';
import { TypeDataEnum } from './DashboardFinanceDept.types';

import {
  FETCH_DASHBOARD_INVOICES_QUERY,
  FetchDashboardInvoicesQueryResponse
} from '../../../../../../../../invoices/queries/fetchDashboardInvoices.query';

import { useFinPaginatedInvoices } from '../../../../../../../../invoices/hooks/useFinPaginatedInvoices';

import { DashboardFinanceDebtAmount } from './components/DashboardFinanceDebtAmount';
import { DashboardFinanceDebtQuantity } from './components/DashboardFinanceDebtQuantity';

import { Translate } from '../../../../../../../../../helpers/Translate';
import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import { MultiSelect } from '../../../../../../../../../helpers/MultiSelect';

import { profilesKeys, words } from '../../../../../../../../../locales/keys';

import { InvoiceCache } from '../../../../../../../../invoices/InvoiceCache';
import { TeamPath } from '../../../../../../../../teams/TeamPath';
import { InvoicePath } from '../../../../../../../../invoices/InvoicePath';

const selectTypeData = [
  {
    value: TypeDataEnum.QUANTITY,
    label: <Translate id={words.quantity} />
  },
  { value: TypeDataEnum.AMOUNT, label: <Translate id={words.amount} /> }
];

const statusInvoices = [
  InvoiceStatuses.SENT,
  InvoiceStatuses.OPEN,
  InvoiceStatuses.UNCOLLECTIBLE
];

interface DashboardFinanceDeptProps {
  selfProfile: boolean;
  teamNanoId: TeamNanoID;
}

function DashboardFinanceDept({
  teamNanoId,
  selfProfile
}: DashboardFinanceDeptProps) {
  const { invoices, invoicesError, invoicesFetched } =
    useFinPaginatedInvoices<FetchDashboardInvoicesQueryResponse>({
      cacheKey: InvoiceCache.dashboardDebtInvoicesCacheKey(),
      initialLimit: 1000,
      initialFilters: {
        invoiceType: { eq: InvoiceTypes.CONTRACTOR },
        companyNanoId: { eq: teamNanoId },
        status: { in: statusInvoices }
      },
      initialSort: [FetchInvoicesSortTypes.CREATED_AT_DESC],
      query: FETCH_DASHBOARD_INVOICES_QUERY,
      options: {
        enabled: !!teamNanoId,
        enabledPlaceholder: !!teamNanoId
      }
    });

  const [typeData, setTypeData] = useState<TypeDataEnum>(TypeDataEnum.AMOUNT);

  if (!teamNanoId) {
    return null;
  }

  return (
    <div className="flex flex-col rounded-lg bg-white border-gray-200 dark:bg-gray-850 p-4 dark:border-gray-800 border">
      <div className="flex justify-between items-start mb-2">
        <div className="flex items-center gap-2 text-md dark:text-gray-300">
          <Translate id={profilesKeys.debt} />
        </div>

        <MultiSelect
          data={selectTypeData}
          multi={false}
          onChange={(v) =>
            setTypeData(isArray(v) ? null : (v.value as TypeDataEnum))
          }
          value={find(selectTypeData, ['value', typeData])}
          classNamePrefix="av_select"
          inputWrapperClassName="w-28 text-sm font-normal"
        />
      </div>

      {typeData === TypeDataEnum.QUANTITY && (
        <DashboardFinanceDebtQuantity
          items={invoices}
          loaded={invoicesFetched}
          href={
            selfProfile
              ? InvoicePath.paymentsContractorInvoices()
              : TeamPath.companyPaymentsContractorInvoices(teamNanoId)
          }
        />
      )}

      {typeData === TypeDataEnum.AMOUNT && (
        <DashboardFinanceDebtAmount
          items={invoices}
          loaded={invoicesFetched}
          href={
            selfProfile
              ? InvoicePath.paymentsContractorInvoices()
              : TeamPath.companyPaymentsContractorInvoices(teamNanoId)
          }
        />
      )}

      <AlertMessage message={invoicesError} />
    </div>
  );
}

export default DashboardFinanceDept;
