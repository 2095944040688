import React from 'react';

import { ProjectNanoID } from '../../../../../../../../projects/projectsTypes';

import {
  FETCH_SHOW_PROJECT_QUERY,
  FetchShowProjectQueryResponse
} from '../../../../../../../../projects/queries/fetchShowProject.query';
import { FetchProjectMessagesQueryResponse } from '../../../../../../../../messages/queries/fetchProjectMessages.query';

import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';
import { useProject } from '../../../../../../../../projects/hooks/useProject';
import { useProjectMessagesContentCreateMessage } from '../../../../../../../../projects/components/content/ProjectMessagesContent/hooks/useProjectMessagesContentCreateMessage';

import { DashboardActiveTaskProjectMessagesHeader } from './components/DashboardActiveTaskProjectMessagesHeader';
import { ProjectMessagesContentMessagesList } from '../../../../../../../../projects/components/content/ProjectMessagesContent/components/ProjectMessagesContentMessagesList';
import { ItemCreateMessageForm } from '../../../../../../../../messages/components/forms/ItemCreateMessageForm';

import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';

import { ProjectsPermissions } from '../../../../../../../../projects/projectsConstants';
import { ProjectCache } from '../../../../../../../../projects/ProjectCache';

interface DashboardActiveTaskProjectMessagesProps {
  projectNanoId?: ProjectNanoID;
}

function DashboardActiveTaskProjectMessages({
  projectNanoId
}: DashboardActiveTaskProjectMessagesProps) {
  const currentUser = useCurrentUser();

  const { project, projectError, projectFetched, projectIsPlaceholderData } =
    useProject<FetchShowProjectQueryResponse>({
      projects: [],
      cacheKey: ProjectCache.showCacheKey(),
      query: FETCH_SHOW_PROJECT_QUERY,
      uuid: projectNanoId,
      options: {
        enabled: !!projectNanoId,
        enabledPlaceholder: !!projectNanoId
      }
    });

  const {
    sendingMessages,
    handleCreateMessageInProject,
    handleFetchMessagesSuccess,
    handleRemoveSendingMessage
  } = useProjectMessagesContentCreateMessage<FetchProjectMessagesQueryResponse>(
    {
      project
    }
  );

  return (
    <div className="h-full flex-1 border-l-4 border-double dark:border-gray-800">
      {projectNanoId ? (
        <div className="h-full flex flex-col">
          <AlertMessage addClassName="mt-4" message={projectError} />
          <LoadingSkeleton loaded={projectFetched || projectIsPlaceholderData}>
            <DashboardActiveTaskProjectMessagesHeader project={project} />

            {project ? (
              <ProjectMessagesContentMessagesList
                projectNanoId={projectNanoId}
                projectUuid={project.uuid}
                sendingMessages={sendingMessages}
                onFetchMessagesSuccess={handleFetchMessagesSuccess}
                onRemoveSendingMessage={handleRemoveSendingMessage}
              />
            ) : null}

            {project ? (
              <ItemCreateMessageForm
                projectNanoId={projectNanoId}
                withFullscreenDropzone={currentUser.hasPermissions(
                  ProjectsPermissions.READ_PROJECT_CREATE_MESSAGE_FORM_FULLSCREEN_DROPZONE
                )}
                withClientLocalTime={currentUser.hasPermissions(
                  ProjectsPermissions.READ_PROJECT_CLIENT_LOCAL_TIME
                )}
                withPasteFiles={currentUser.hasPermissions(
                  ProjectsPermissions.READ_PROJECT_CREATE_MESSAGE_FORM_PASTE_FILES
                )}
                onSubmit={handleCreateMessageInProject}
                teamNanoId={project.team?.nanoId}
              />
            ) : null}
          </LoadingSkeleton>
        </div>
      ) : null}
    </div>
  );
}

export default DashboardActiveTaskProjectMessages;
