import React, { useCallback, useMemo, useState } from 'react';

import SplitPane from 'react-split-pane';
import map from 'lodash/map';

import {
  FetchTasksSortTypes,
  TaskNanoID,
  TaskStatuses
} from '../../../../../../tasks/tasksTypes';
import {
  ProjectNanoID,
  ProjectStatuses
} from '../../../../../../projects/projectsTypes';

import {
  FETCH_TASKS_DASHBOARD_QUERY,
  FetchTasksDashboardQueryResponse
} from '../../../../../../tasks/queries/fetchTasksDashboard.query';
import {
  FETCH_DASHBOARD_TASK_MEMBER_INVITES_QUERY,
  FetchDashboardTaskMemberInvitesQueryResponse
} from '../../../../../../taskMemberInvites/queries/fetchDashboardTaskMemberInvites.query';
import {
  FETCH_PROJECTS_DASHBOARD_QUERY,
  FetchProjectsDashboardQueryResponse
} from '../../../../../../projects/queries/fetchProjectsDashboard.query';

import { useTasks } from '../../../../../../tasks/hooks/useTasks';
import { useProjects } from '../../../../../../projects/hooks/useProjects';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useTaskMemberInvites } from '../../../../../../taskMemberInvites/hooks/useTaskMemberInvites';

import { DashboardActiveTaskMessages } from './components/DashboardActiveTaskMessages';
import { DashboardActiveTaskProjectMessages } from './components/DashboardActiveTaskProjectMessages';
import {
  DashboardActiveTasksList,
  DashboardActiveTasksListActiveTasks,
  DashboardActiveTasksListItemScope,
  DashboardActiveTasksListUser
} from './components/DashboardActiveTasksList';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { Translate } from '../../../../../../../helpers/Translate';
import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';

import { TasksPermissions } from '../../../../../../tasks/tasksConstants';
import { ProjectsPermissions } from '../../../../../../projects/projectsConstants';
import { UsersPermissions } from '../../../../../usersConstants';

import { TaskCache } from '../../../../../../tasks/TaskCache';
import { ProjectCache } from '../../../../../../projects/ProjectCache';
import { TaskMemberInviteCache } from '../../../../../../taskMemberInvites/TaskMemberInviteCache';

import { tasksKeys } from '../../../../../../../locales/keys';

type SelectedItem = {
  nanoId: TaskNanoID | ProjectNanoID;
  scope: DashboardActiveTasksListItemScope;
};

interface DashboardActiveTasksProps {
  user: DashboardActiveTasksListUser;
  selfProfile: boolean;
}

const activeTasksStatuses = [
  TaskStatuses.IN_PROGRESS,
  TaskStatuses.QUALITY_CONTROL,
  TaskStatuses.READY_FOR_ACCEPTANCE,
  TaskStatuses.PENDING_MORE_INFO,
  TaskStatuses.PAUSED
];

const initialLimit = 24;

function DashboardActiveTasks({
  selfProfile,
  user
}: DashboardActiveTasksProps) {
  const currentUser = useCurrentUser();

  const enabledProjects = currentUser.hasPermissions(
    UsersPermissions.READ_DASHBOARD_ACTIVE_TASKS_BLOCK_WITH_PROJECTS
  );

  const [selectedItem, setSelectedItem] = useState<SelectedItem>();

  const {
    tasks,
    tasksError,
    tasksTotalCount,
    tasksFetched,
    tasksIsPlaceholderData,
    hasNextTasksPage,
    tasksFetchingNextPage,
    loadMoreTasks
  } = useTasks<FetchTasksDashboardQueryResponse>({
    cacheKey: TaskCache.userActiveTasksCacheKey(),
    query: FETCH_TASKS_DASHBOARD_QUERY,
    initialLimit,
    initialSort: [FetchTasksSortTypes.LAST_ACTIVITY_DESC],
    initialFilters: {
      performerIds: [user.id],
      status: { in: activeTasksStatuses }
    }
  });

  const {
    projects,
    projectsError,
    projectsTotalCount,
    projectsFetched,
    projectsIsPlaceholderData,
    projectsHasNextPage,
    projectsFetchingNextPage,
    loadMoreProjects
  } = useProjects<FetchProjectsDashboardQueryResponse>({
    cacheKey: ProjectCache.showCacheKey(),
    query: FETCH_PROJECTS_DASHBOARD_QUERY,
    initialLimit,
    initialFilters: {
      performerIds: [user.id],
      status: { notIn: [ProjectStatuses.CANCELED, ProjectStatuses.DONE] }
    },
    options: {
      enabled: enabledProjects,
      enabledPlaceholder: enabledProjects
    }
  });

  const loadMoreActiveTasks = useCallback(() => {
    loadMoreTasks();
    loadMoreProjects();
  }, [loadMoreProjects, loadMoreTasks]);

  const {
    taskMemberInvites,
    taskMemberInvitesError,
    taskMemberInvitesTotalCount,
    taskMemberInvitesFetched,
    taskMemberInvitesIsPlaceholderData,
    hasNextTaskMemberInvitesPage,
    taskMemberInvitesFetchingNextPage,
    loadMoreTaskMemberInvites
  } = useTaskMemberInvites<FetchDashboardTaskMemberInvitesQueryResponse>({
    cacheKey: TaskMemberInviteCache.userTaskMemberInvitesCacheKey(user.nanoId),
    query: FETCH_DASHBOARD_TASK_MEMBER_INVITES_QUERY,
    initialLimit,
    initialFilters: {
      invitedUserNanoId: user.nanoId,
      taskStatusNe: [TaskStatuses.CANCELED, TaskStatuses.DONE]
    },
    options: {
      enabled: !user.blocked,
      enabledPlaceholder: !user.blocked
    }
  });

  const activeTasks = useMemo<DashboardActiveTasksListActiveTasks>(() => {
    const modifyTasks: DashboardActiveTasksListActiveTasks = map(
      tasks,
      (task) => ({
        ...task,
        scope: DashboardActiveTasksListItemScope.TASK
      })
    );

    const modifyProjects: DashboardActiveTasksListActiveTasks = map(
      projects,
      (project) => ({
        ...project,
        scope: DashboardActiveTasksListItemScope.PROJECT
      })
    );

    const allItems = [...modifyTasks, ...modifyProjects];

    return allItems.sort((a, b) => {
      if (a.lastMessage?.createdAt && b.lastMessage?.createdAt) {
        return (
          new Date(b.lastMessage.createdAt).getTime() -
          new Date(a.lastMessage.createdAt).getTime()
        );
      }

      if (a.lastMessage?.createdAt && !b.lastMessage?.createdAt) {
        return -1;
      }

      if (!a.lastMessage?.createdAt && b.lastMessage?.createdAt) {
        return 1;
      }

      return 0;
    });
  }, [projects, tasks]);

  return (
    <div>
      <div className="relative border dark:border-gray-800 rounded-lg overflow-hidden lg:items-stretch bg-white dark:bg-gray-900">
        <AlertMessage
          addClassName="mt-4"
          message={tasksError || taskMemberInvitesError || projectsError}
        />
        <LoadingSkeleton
          loaded={
            (tasksFetched || tasksIsPlaceholderData) &&
            (!enabledProjects ||
              projectsFetched ||
              projectsIsPlaceholderData) &&
            (user.blocked ||
              taskMemberInvitesFetched ||
              taskMemberInvitesIsPlaceholderData)
          }
        >
          {tasksTotalCount === 0 &&
          taskMemberInvitesTotalCount === 0 &&
          projectsTotalCount === 0 ? null : (
            <SplitPane
              split="vertical"
              defaultSize="512px"
              maxSize={-200}
              minSize={200}
              style={{ position: 'static', minHeight: '444px' }}
              paneStyle={{
                position: 'static',
                overflow: 'auto'
              }}
              resizerClassName="Resizer"
            >
              <DashboardActiveTasksList
                selfProfile={selfProfile}
                user={user}
                activeTasks={activeTasks}
                activeTasksCount={tasksTotalCount + projectsTotalCount}
                taskMemberInvites={taskMemberInvites}
                invitationsCount={taskMemberInvitesTotalCount}
                selectedItem={selectedItem}
                onSelectedItem={setSelectedItem}
                activeTasksError={tasksError || projectsError}
                hasNextActiveTasksPage={hasNextTasksPage || projectsHasNextPage}
                loadMoreActiveTasks={loadMoreActiveTasks}
                taskMemberInvitesError={taskMemberInvitesError}
                hasNextTaskMemberInvitesPage={hasNextTaskMemberInvitesPage}
                loadMoreTaskMemberInvites={loadMoreTaskMemberInvites}
                isLoading={
                  tasksFetchingNextPage ||
                  projectsFetchingNextPage ||
                  taskMemberInvitesFetchingNextPage
                }
              />

              <div className="h-full hidden lg:block">
                {!selectedItem && (
                  <div className="h-full flex-1 border-l-4 border-double dark:border-gray-800">
                    <div className="h-full flex items-center justify-center">
                      <div className="font-medium text-sm text-gray-600 dark:text-gray-400">
                        <Translate id={tasksKeys.selectTaskToSeeMessages} />
                      </div>
                    </div>
                  </div>
                )}

                {selectedItem?.scope ===
                  DashboardActiveTasksListItemScope.TASK && (
                  <CheckPermissions
                    action={TasksPermissions.READ_TASK_MESSAGES_PAGE}
                  >
                    <DashboardActiveTaskMessages
                      taskNanoId={selectedItem.nanoId as TaskNanoID}
                    />
                  </CheckPermissions>
                )}

                {selectedItem?.scope ===
                  DashboardActiveTasksListItemScope.PROJECT && (
                  <CheckPermissions
                    action={ProjectsPermissions.READ_PROJECT_MESSAGES_PAGE}
                  >
                    <DashboardActiveTaskProjectMessages
                      projectNanoId={selectedItem.nanoId as ProjectNanoID}
                    />
                  </CheckPermissions>
                )}
              </div>
            </SplitPane>
          )}
        </LoadingSkeleton>
      </div>
    </div>
  );
}

export default DashboardActiveTasks;
