import { gql } from 'graphql-request';

import { MayBe } from '../../../types';

import {
  ProjectBurning,
  ProjectCreatedAt,
  ProjectDescription,
  ProjectID,
  ProjectImplementationDate,
  ProjectName,
  ProjectSetDate,
  ProjectStatus,
  ProjectTagsLocalizedName,
  ProjectTagsUUID,
  ProjectUserFullName,
  ProjectUserImageFile,
  ProjectUserImageUUID,
  ProjectUserUUID,
  ProjectUserID,
  ProjectUUID,
  ProjectFavorite,
  ProjectNotificationsEnabled,
  ProjectActiveTasksCount,
  ProjectPerformedTasksCount,
  ProjectCanceledTasksCount,
  ProjectAcceptanceTasksCount,
  ProjectDefinitionTasksCount,
  ProjectIncompleteSpecificationTasksCount,
  ProjectQaTasksCount,
  ProjectImplementationTasksCount,
  ProjectEstimateCost,
  ProjectDebt,
  ProjectProjectAccount,
  ProjectTasksAmountsSum,
  ProjectTasksPrepaymentsSum,
  ProjectRequirements,
  ProjectMembersID,
  ProjectMembersUUID,
  ProjectMembersFullName,
  ProjectMembersImageUUID,
  ProjectMembersImageFile,
  ProjectCommission,
  ProjectCreditCommission,
  ProjectHostingCommission,
  ProjectTagsID,
  ProjectUnpaidBillsSum,
  ProjectNanoID,
  ProjectUserNanoID,
  ProjectMembersNanoID,
  ProjectClientDebt,
  ProjectTasksClientStatus,
  ProjectMembersFinanceRoleName,
  ProjectUserFinanceRoleName,
  ProjectTaskUUID,
  ProjectUserClient,
  ProjectUserNDA,
  ProjectUserBlocked,
  ProjectMembersBlocked,
  ProjectTeamNanoID,
  ProjectTeamName,
  ProjectTeamImageFile,
  ProjectMembersClient,
  ProjectUserCurrentTeamNanoID,
  ProjectMembersCurrentTeamNanoID,
  ProjectOwnerID,
  ProjectOwnerUUID,
  ProjectOwnerNanoID,
  ProjectOwnerFullName,
  ProjectOwnerFinanceRoleName,
  ProjectOwnerBlocked,
  ProjectOwnerCurrentTeamNanoID,
  ProjectOwnerImageUUID,
  ProjectOwnerImageFile,
  ProjectOwnerNDA,
  ProjectOwnerClient
} from '../projectsTypes';

export interface FetchShowProjectQueryResponse {
  id: ProjectID;
  uuid: ProjectUUID;
  nanoId: ProjectNanoID;
  favorite: ProjectFavorite;
  notificationsEnabled: ProjectNotificationsEnabled;
  name: ProjectName;
  description: ProjectDescription;
  status: ProjectStatus;
  tasksClientStatus: ProjectTasksClientStatus;
  burning: ProjectBurning;
  setDate: ProjectSetDate;
  implementationDate: ProjectImplementationDate;
  createdAt: ProjectCreatedAt;
  activeTasksCount: ProjectActiveTasksCount;
  performedTasksCount: ProjectPerformedTasksCount;
  canceledTasksCount: ProjectCanceledTasksCount;
  acceptanceTasksCount: ProjectAcceptanceTasksCount;
  definitionTasksCount: ProjectDefinitionTasksCount;
  incompleteSpecificationTasksCount: ProjectIncompleteSpecificationTasksCount;
  qaTasksCount: ProjectQaTasksCount;
  implementationTasksCount: ProjectImplementationTasksCount;
  estimateCost: ProjectEstimateCost;
  debt: ProjectDebt;
  clientDebt: ProjectClientDebt;
  projectAccount: ProjectProjectAccount;
  tasksAmountsSum: ProjectTasksAmountsSum;
  tasksPrepaymentsSum: ProjectTasksPrepaymentsSum;
  commission: ProjectCommission;
  creditCommission: ProjectCreditCommission;
  hostingCommission: ProjectHostingCommission;
  requirements: ProjectRequirements;
  unpaidBillsSum: ProjectUnpaidBillsSum;
  user: {
    id: ProjectUserID;
    uuid: ProjectUserUUID;
    nanoId: ProjectUserNanoID;
    fullName: ProjectUserFullName;
    financeRoleName: ProjectUserFinanceRoleName;
    blocked: ProjectUserBlocked;
    currentTeam: MayBe<{
      nanoId: ProjectUserCurrentTeamNanoID;
    }>;
    image: {
      uuid: ProjectUserImageUUID;
      file: ProjectUserImageFile;
    };
    nda: ProjectUserNDA;
    client: ProjectUserClient;
  };
  owner: {
    id: ProjectOwnerID;
    uuid: ProjectOwnerUUID;
    nanoId: ProjectOwnerNanoID;
    fullName: ProjectOwnerFullName;
    financeRoleName: ProjectOwnerFinanceRoleName;
    blocked: ProjectOwnerBlocked;
    currentTeam: MayBe<{
      nanoId: ProjectOwnerCurrentTeamNanoID;
    }>;
    image: {
      uuid: ProjectOwnerImageUUID;
      file: ProjectOwnerImageFile;
    };
    nda: ProjectOwnerNDA;
    client: ProjectOwnerClient;
  };
  members: {
    id: ProjectMembersID;
    uuid: ProjectMembersUUID;
    nanoId: ProjectMembersNanoID;
    fullName: ProjectMembersFullName;
    financeRoleName: ProjectMembersFinanceRoleName;
    blocked: ProjectMembersBlocked;
    client: ProjectMembersClient;
    currentTeam: MayBe<{
      nanoId: ProjectMembersCurrentTeamNanoID;
    }>;
    image: {
      uuid: ProjectMembersImageUUID;
      file: ProjectMembersImageFile;
    };
  }[];
  tags: {
    id: ProjectTagsID;
    uuid: ProjectTagsUUID;
    localizedName: ProjectTagsLocalizedName;
  }[];
  tasks: {
    id: ProjectTaskUUID;
    status: string;
    visibleForClient: boolean;
  }[];
  team: {
    nanoId: ProjectTeamNanoID;
    name: ProjectTeamName;
    image: {
      file: ProjectTeamImageFile;
    };
  };
}

export const FETCH_SHOW_PROJECT_QUERY = gql`
  query ShowProject($uuid: ID!) {
    project(uuid: $uuid) {
      id
      uuid
      nanoId
      favorite
      notificationsEnabled
      name
      description
      status
      tasksClientStatus
      burning
      setDate
      implementationDate
      createdAt
      activeTasksCount
      performedTasksCount
      canceledTasksCount
      acceptanceTasksCount
      definitionTasksCount
      incompleteSpecificationTasksCount
      qaTasksCount
      implementationTasksCount
      estimateCost
      debt
      clientDebt
      projectAccount
      tasksAmountsSum
      tasksPrepaymentsSum
      commission
      creditCommission
      hostingCommission
      requirements
      unpaidBillsSum
      tasks {
        nanoId
        status
        visibleForClient
      }
      user {
        id
        uuid
        nanoId
        fullName
        financeRoleName
        client
        nda
        blocked
        currentTeam {
          nanoId
        }
        image {
          uuid
          file
        }
      }
      owner {
        id
        uuid
        nanoId
        fullName
        financeRoleName
        client
        nda
        blocked
        currentTeam {
          nanoId
        }
        image {
          uuid
          file
        }
      }
      members {
        id
        uuid
        nanoId
        fullName
        financeRoleName
        blocked
        client
        currentTeam {
          nanoId
        }
        image {
          uuid
          file
        }
      }
      tags {
        id
        uuid
        name
        localizedName
      }
      team {
        name
        nanoId
        image {
          file
        }
      }
    }
  }
`;
