import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isSameDay from 'date-fns/isSameDay';
import find from 'lodash/find';
import filter from 'lodash/filter';
import map from 'lodash/map';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';

import {
  AccountBalanceBalance,
  AccountBalanceCreatedAt,
  AccountBalanceID,
  AccountBalancePrevBalance
} from '../../../../../../../../../../accountBalances/accountBalancesTypes';
import { AccountAccountTypeChartNumber } from '../../../../../../../../../../accounts/accountsTypes';

import { DashboardFinanceBalancesAccountTypeChartNumber } from '../../DashboardFinanceBalancesChart.types';

import { dateFnsConvert } from '../../../../../../../../../../../utils/dateFnsConvert';

interface AccountBalanceDataType {
  id: AccountBalanceID;
  createdAt: AccountBalanceCreatedAt;
  balance: AccountBalanceBalance;
  prevBalance: AccountBalancePrevBalance;
  account: {
    accountType: {
      chartNumber: AccountAccountTypeChartNumber;
    };
  };
}

type DataType = {
  x: string;
  y: number;
};

interface GetAccountBalancesData {
  accountBalances: AccountBalanceDataType[];
  chartNumbers: DashboardFinanceBalancesAccountTypeChartNumber[];
  startDate: Date;
}

function getAccountBalanceClosestData(
  accountBalances: AccountBalanceDataType[],
  date: Date
): number {
  if (isEmpty(accountBalances)) {
    return 0;
  }

  const dateDifference = map(
    accountBalances,
    (accountBalance) =>
      date.getTime() - new Date(accountBalance.createdAt).getTime()
  );

  const minDateDifference = Math.min(...dateDifference);

  const minDateDifferenceIndex = dateDifference.indexOf(minDateDifference);

  return new Date(accountBalances[minDateDifferenceIndex].createdAt).getDate() >
    date.getDate()
    ? accountBalances[minDateDifferenceIndex].prevBalance
    : accountBalances[minDateDifferenceIndex].balance;
}

function getAccountBalancesData({
  accountBalances,
  chartNumbers,
  startDate
}: GetAccountBalancesData): DataType[] {
  const period = eachDayOfInterval({ start: startDate, end: new Date() });

  if (isEmpty(accountBalances)) {
    return map(period, (date) => ({
      x: dateFnsConvert.toCustomFormatDate('d MMM', date.toISOString()),
      y: 0
    }));
  }

  return map(period, (date) => {
    const chartNumberAccountBalance = filter(
      accountBalances,
      (accountBalance) =>
        some(
          chartNumbers,
          (chartNumber) =>
            accountBalance.account.accountType.chartNumber === chartNumber
        )
    );

    const accountBalance = find(chartNumberAccountBalance, (accountBalance) =>
      isSameDay(date, new Date(accountBalance.createdAt))
    );

    const balance = accountBalance
      ? accountBalance.balance
      : getAccountBalanceClosestData(chartNumberAccountBalance, date);

    return {
      x: dateFnsConvert.toCustomFormatDate('d MMM', date.toISOString()),
      y: balance
    };
  });
}

export default getAccountBalancesData;
