import {
  ErrorMessage,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { TextAreaField } from '../../../../../helpers/FormFields/TextAreaField';

import { formsFields, stringsKeys } from '../../../../../locales/keys';

interface TrackTimeInTaskFormProps {
  form: string;
  isLoading?: IsLoading;
  registerBody: RegisterFormFieldType<HTMLTextAreaElement>;
  registerHours: RegisterFormFieldType<HTMLInputElement>;
  registerMinutes: RegisterFormFieldType<HTMLInputElement>;
  hoursValidationError?: ErrorMessage;
  minutesValidationError?: ErrorMessage;
}

function TrackTimeInTaskForm({
  form,
  isLoading,
  registerBody,
  registerHours,
  registerMinutes,
  hoursValidationError,
  minutesValidationError
}: TrackTimeInTaskFormProps) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <div className="flex flex-wrap -my-2 -mx-1">
          <div className="w-full">
            <div className="my-2 mx-1">
              <TextAreaField
                disabled={isLoading}
                i18nPlaceholder={
                  stringsKeys.thisCommentWouldBeVisibleOnlyInTimeline
                }
                rows={2}
                i18nLabel={formsFields.description}
                name={registerBody.name}
                onChange={registerBody.onChange}
                ref={registerBody.ref}
              />
            </div>
          </div>
          <div className="w-full sm:w-1/2">
            <InputField
              className="my-2 mx-1"
              disabled={isLoading}
              inputWrapperClassName="relative mt-1"
              error={hoursValidationError}
              showErrorIcon={false}
              i18nPlaceholder="0-7"
              i18nLabel={formsFields.hours}
              name={registerHours.name}
              onChange={registerHours.onChange}
              ref={registerHours.ref}
              min={0}
              max={7}
              type="number"
            />
          </div>
          <div className="w-full sm:w-1/2">
            <InputField
              className="my-2 mx-1"
              inputWrapperClassName="relative mt-1"
              disabled={isLoading}
              error={minutesValidationError}
              showErrorIcon={false}
              i18nPlaceholder="0-59"
              i18nLabel={formsFields.minutes}
              name={registerMinutes.name}
              onChange={registerMinutes.onChange}
              ref={registerMinutes.ref}
              min={0}
              max={59}
              type="number"
            />
          </div>
        </div>
      </div>
    </Form>
  );
}

export default TrackTimeInTaskForm;
