import React, { Fragment } from 'react';

import {
  TaskAmount,
  TaskName,
  TaskNanoID,
  TaskPrepayment,
  TaskProjectName,
  TaskProjectNanoID,
  TaskStatus,
  TaskStatuses,
  TaskUUID
} from '../../../../../../../../../../tasks/tasksTypes';

import { useCurrentUser } from '../../../../../../../../../../../auth/hooks/useAuth';

import { TrackTimeInTaskModalButton } from '../../../../../../../../../../tasks/components/modalButtons/TrackTimeInTaskModalButton';
import { SubmitResultsToTaskModalButton } from '../../../../../../../../../../tasks/components/modalButtons/SubmitResultsToTaskModalButton';
import { TaskCheckInButton } from '../../../../../../../../../../tasks/components/modalButtons/TaskCheckInButton';

import { NextPureLinkHelper } from '../../../../../../../../../../../helpers/links/NextPureLinkHelper';
import { Status } from '../../../../../../../../../../../helpers/Status';
import { Progress } from '../../../../../../../../../../../helpers/Progress';
import { MoneyHelper } from '../../../../../../../../../../../helpers/MoneyHelper';
import { CheckPermissions } from '../../../../../../../../../../../helpers/CheckPermissions';

import { TaskPath } from '../../../../../../../../../../tasks/TaskPath';
import { ProjectPath } from '../../../../../../../../../../projects/ProjectPath';

import { TasksPermissions } from '../../../../../../../../../../tasks/tasksConstants';
import { TaskMemberInviteCache } from '../../../../../../../../../../taskMemberInvites/TaskMemberInviteCache';
import { IconsEnum } from '../../../../../../../../../../../assets/icons/types';
import { tasksKeys } from '../../../../../../../../../../../locales/keys';

interface DashboardActiveTaskMessageHeaderTask {
  uuid: TaskUUID;
  name: TaskName;
  nanoId: TaskNanoID;
  status: TaskStatus;
  amount: TaskAmount;
  prepayment: TaskPrepayment;
  project: {
    nanoId: TaskProjectNanoID;
    name: TaskProjectName;
  };
}

interface DashboardActiveTaskMessageHeaderProps {
  task: DashboardActiveTaskMessageHeaderTask;
  isTaskMember: boolean;
}

function DashboardActiveTaskMessageHeader({
  task,
  isTaskMember
}: DashboardActiveTaskMessageHeaderProps) {
  const currentUser = useCurrentUser();

  if (!task) {
    return null;
  }

  const withSubmitResultsButton =
    isTaskMember &&
    task.status !== TaskStatuses.DONE &&
    task.status !== TaskStatuses.SETTING_TASK &&
    task.status !== TaskStatuses.CANCELED;

  const withTrackTimeButton =
    isTaskMember &&
    task.status !== TaskStatuses.DONE &&
    task.status !== TaskStatuses.CANCELED;

  const withCheckInButton =
    !isTaskMember && task.status === TaskStatuses.SETTING_TASK;

  return (
    <div className="px-2 pb-2 pt-1 flex items-center border-b dark:border-gray-800">
      <div className="flex-1">
        <div className="flex space-x-1.5 pr-10">
          {task.project && (
            <Fragment>
              <NextPureLinkHelper
                text={task.project.name}
                href={ProjectPath.show(task.project.nanoId)}
                className="text-sm mb-1 hover:underline min-w-5 line-clamp-1 break-all"
              />
              <span>/</span>
            </Fragment>
          )}

          <NextPureLinkHelper
            text={task.name}
            href={TaskPath.show(task.nanoId)}
            className="pr-10 text-sm mb-1 hover:underline font-medium min-w-fit text-black dark:text-white line-clamp-1 break-all"
          />
        </div>

        <div className="flex gap-2">
          <Status status={task.status} />

          {
            <div className="w-32">
              <div className="flex gap-2 items-center leading-tight">
                <div className="text-gray-900 dark:text-gray-200 font-normal text-sm">
                  <span className="mt-1">
                    <MoneyHelper value={task.amount} />
                  </span>
                </div>
                {task.amount !== 0 && (
                  <div className="text-xs text-gray-500 whitespace-nowrap">
                    <span>
                      <MoneyHelper value={task.prepayment} />
                    </span>
                  </div>
                )}
              </div>

              <Progress min={task.prepayment} max={task.amount} />
            </div>
          }
        </div>
      </div>
      <div className="flex gap-x-px">
        {withCheckInButton && (
          <CheckPermissions action={TasksPermissions.READ_CHECK_IN_TASK_BUTTON}>
            {task.status !== 'canceled' && task.status !== 'performed' && (
              <TaskCheckInButton
                taskNanoId={task.nanoId}
                icon={IconsEnum.CLIPBOARD_DOCUMENT_CHECK_OUTLINE}
                className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                iconClassName="h-6 w-6"
                cacheKeys={[
                  TaskMemberInviteCache.userTaskMemberInvitesCacheKey(
                    currentUser.nanoId
                  )
                ]}
              />
            )}
          </CheckPermissions>
        )}

        {withTrackTimeButton && (
          <CheckPermissions
            action={TasksPermissions.READ_TASK_TRACK_TIME_BUTTON}
          >
            <TrackTimeInTaskModalButton
              className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              taskUuid={task.uuid}
              taskNanoId={task.nanoId as TaskNanoID}
              icon={IconsEnum.CLOCK_OUTLINE}
              tooltipI18nText={tasksKeys.trackTime}
            />
          </CheckPermissions>
        )}

        {withSubmitResultsButton && (
          <CheckPermissions
            action={TasksPermissions.READ_TASK_SUBMIT_RESULTS_ACTION_BUTTON}
          >
            {task.status !== 'canceled' && task.status !== 'performed' && (
              <SubmitResultsToTaskModalButton
                taskNanoId={task.nanoId}
                icon={IconsEnum.ROCKET_OUTLINE}
                className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                iconClassName="h-6 w-6"
                tooltipI18nText={tasksKeys.submitResults}
              />
            )}
          </CheckPermissions>
        )}
      </div>
    </div>
  );
}

export default DashboardActiveTaskMessageHeader;
