import {
  UserCompetencyID,
  UserCompetencyNanoID
} from './userCompetenciesTypes';

const baseUrl = 'user_competencies';

export class UserCompetencyBffUrl {
  static index() {
    return baseUrl;
  }

  static create() {
    return baseUrl;
  }

  static delete(userCompetencyNanoId: UserCompetencyNanoID) {
    return `${baseUrl}/${userCompetencyNanoId}`;
  }

  static update(userCompetencyId: UserCompetencyID) {
    return `${baseUrl}/${userCompetencyId}`;
  }

  static makeDefault(
    userCompetencyId: UserCompetencyID | UserCompetencyNanoID
  ) {
    return `${baseUrl}/${userCompetencyId}/make_default`;
  }
}
