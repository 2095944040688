import React, { useCallback } from 'react';
import find from 'lodash/find';
import isArray from 'lodash/isArray';

import { MultiSelectChangeCallbackType } from '../../../../../../../../../helpers/MultiSelect/types';
import { DashboardTrackedTimeFilterType } from '../../DashboardTrackedTime.types';

import { MultiSelect } from '../../../../../../../../../helpers/MultiSelect';
import { Translate } from '../../../../../../../../../helpers/Translate';

import { words } from '../../../../../../../../../locales/keys';

const options = [
  {
    value: DashboardTrackedTimeFilterType.DAY,
    label: <Translate id={words.oneDay} />
  },
  {
    value: DashboardTrackedTimeFilterType.WEEK,
    label: <Translate id={words.oneWeek} />
  },
  {
    value: DashboardTrackedTimeFilterType.MONTH,
    label: <Translate id={words.oneMonth} />
  }
];

interface DashboardTrackedTimeSelectTypeProps {
  defaultValue: DashboardTrackedTimeFilterType;
  onChange: (value: DashboardTrackedTimeFilterType) => void;
}

function DashboardTrackedTimeSelectType({
  defaultValue,
  onChange
}: DashboardTrackedTimeSelectTypeProps) {
  const handleChange = useCallback<MultiSelectChangeCallbackType>(
    (selectOption) => {
      onChange(
        isArray(selectOption)
          ? null
          : (selectOption.value as DashboardTrackedTimeFilterType)
      );
    },
    [onChange]
  );

  return (
    <MultiSelect
      data={options}
      multi={false}
      onChange={handleChange}
      value={find(options, ['value', defaultValue])}
      classNamePrefix="av_select"
      inputWrapperClassName="w-28 text-sm font-normal"
    />
  );
}

export default DashboardTrackedTimeSelectType;
