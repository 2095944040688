import { TaskNanoID, TaskSourceFilesCount } from '../../../../../tasksTypes';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { SendResultsToClientInTaskButton } from '../../../../buttons/SendResultsToClientInTaskButton';
import { SubmitSourceFilesToTaskModalButton } from '../../../../modalButtons/SubmitSourceFilesToTaskModalButton';
import { DownloadTaskSourceFilesButton } from '../../../../buttons/DownloadTaskSourceFilesButton';
import { DownloadTaskResultsModalButton } from '../../../../buttons/DownloadTaskResultsModalButton';
import { RevisionTaskModalButton } from '../../../../modalButtons/RevisionTaskModalButton';

import { DropdownHelper } from '../../../../../../../helpers/dropdowns/DropdownHelper';
import { PopoverPlacement } from '../../../../../../../helpers/Popover/popoverConstants';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { sourceFilesKeys, words } from '../../../../../../../locales/keys';
import { TasksPermissions } from '../../../../../tasksConstants';
import { IconsEnum } from '../../../../../../../assets/icons/types';

interface DashboardTasksResultsListItemDropdownMenuProps {
  taskNanoId: TaskNanoID;
  taskSourceFilesCount: TaskSourceFilesCount;
}

function DashboardTasksResultsListItemDropdownMenu({
  taskNanoId,
  taskSourceFilesCount
}: DashboardTasksResultsListItemDropdownMenuProps) {
  const currentUser = useCurrentUser();

  const withResultsFilesActions =
    currentUser.hasPermissions(
      TasksPermissions.READ_TASK_DOWNLOAD_TASK_RESULTS_BUTTON
    ) ||
    currentUser.hasPermissions(TasksPermissions.READ_TASK_SEND_RESULTS_BUTTON);

  const withSourceFilesActions =
    (taskSourceFilesCount > 0 &&
      currentUser.hasPermissions(
        TasksPermissions.READ_TASK_DOWNLOAD_SOURCE_FILES_BUTTON
      )) ||
    currentUser.hasPermissions(
      TasksPermissions.READ_TASK_UPLOAD_SOURCE_FILES_BUTTON
    );

  if (!withResultsFilesActions && !withSourceFilesActions) {
    return null;
  }

  return (
    <DropdownHelper
      buttonClassName="p-0.5 rounded inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
      icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
      popoverClassName="relative min-w-48 z-10 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700"
      dropdownPlacement={PopoverPlacement.BOTTOM_END}
      iconClassName="w-4 h-4"
    >
      <CheckPermissions action={TasksPermissions.READ_REVISION_TASK_BUTTON}>
        <RevisionTaskModalButton
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
          icon={IconsEnum.REPLY_OUTLINE}
          iconClassName="w-5 h-5 mr-2"
          taskNanoId={taskNanoId}
        />

        <hr className="border-gray-200 dark:border-gray-600 mx-2.5 my-1.5" />
      </CheckPermissions>

      <CheckPermissions
        action={TasksPermissions.READ_TASK_DOWNLOAD_TASK_RESULTS_BUTTON}
      >
        <DownloadTaskResultsModalButton
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
          taskNanoId={taskNanoId}
          iconClassName="w-5 h-5 mr-2"
          i18nText={words.downloadTaskResult}
        />
      </CheckPermissions>

      <CheckPermissions action={TasksPermissions.READ_TASK_SEND_RESULTS_BUTTON}>
        <SendResultsToClientInTaskButton
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
          iconClassName="w-5 h-5 mr-2"
          i18nText={words.sendViaEmail}
          taskNanoId={taskNanoId}
        />
      </CheckPermissions>

      {withResultsFilesActions && withSourceFilesActions && (
        <hr className="border-gray-200 dark:border-gray-600 mx-2.5 my-1.5" />
      )}

      <CheckPermissions
        action={TasksPermissions.READ_TASK_UPLOAD_SOURCE_FILES_BUTTON}
      >
        <SubmitSourceFilesToTaskModalButton
          taskNanoId={taskNanoId}
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
          icon={IconsEnum.CLOUD_UPLOAD_OUTLINE}
          iconClassName="w-5 h-5 mr-2"
          i18nText={sourceFilesKeys.uploadPlural}
        />
      </CheckPermissions>
      {taskSourceFilesCount > 0 && (
        <CheckPermissions
          action={TasksPermissions.READ_TASK_DOWNLOAD_SOURCE_FILES_BUTTON}
        >
          <DownloadTaskSourceFilesButton taskNanoId={taskNanoId} />
        </CheckPermissions>
      )}
    </DropdownHelper>
  );
}
export default DashboardTasksResultsListItemDropdownMenu;
