import React, { Fragment, useCallback, useEffect, useState } from 'react';

import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';

import {
  DashboardActiveTasksListActiveTasks,
  DashboardActiveTasksListInvitations,
  DashboardActiveTasksListItemScope,
  DashboardActiveTasksListSelectedItem,
  DashboardActiveTasksListTabNames,
  DashboardActiveTasksListUser
} from './DashboardActiveTasksList.types';

import { DashboardActiveTasksListItemsList } from './components/DashboardActiveTasksListItemsList';
import { DashboardActiveTasksListTabs } from './components/DashboardActiveTasksListTabs';
import { TaskMemberInvitesList } from '../../../../../../../../taskMemberInvites/components/lists/TaskMemberInvitesList';

import {
  FetchMoreInfiniteButtonHelper,
  FetchMoreInfiniteButtonHelperPlacement
} from '../../../../../../../../../helpers/buttons/FetchMoreInfiniteButtonHelper';

import { TaskMemberInviteCache } from '../../../../../../../../taskMemberInvites/TaskMemberInviteCache';

import { words } from '../../../../../../../../../locales/keys';

interface DashboardActiveTasksListProps {
  user: DashboardActiveTasksListUser;
  activeTasks: DashboardActiveTasksListActiveTasks;
  taskMemberInvites: DashboardActiveTasksListInvitations;
  activeTasksCount: number;
  invitationsCount: number;
  selfProfile: boolean;
  selectedItem: DashboardActiveTasksListSelectedItem;
  onSelectedItem: (value: DashboardActiveTasksListSelectedItem) => void;
  activeTasksError: string;
  hasNextActiveTasksPage: boolean;
  loadMoreActiveTasks: () => void;

  taskMemberInvitesError: string;
  hasNextTaskMemberInvitesPage: boolean;
  loadMoreTaskMemberInvites: () => void;

  isLoading: boolean;
}

function DashboardActiveTasksList({
  user,
  activeTasks,
  taskMemberInvites,
  activeTasksCount,
  invitationsCount,
  selfProfile,
  onSelectedItem,
  selectedItem,
  activeTasksError,
  hasNextActiveTasksPage,
  loadMoreActiveTasks,
  taskMemberInvitesError,
  hasNextTaskMemberInvitesPage,
  loadMoreTaskMemberInvites,
  isLoading
}: DashboardActiveTasksListProps) {
  const [selectTab, setSelectTab] = useState<DashboardActiveTasksListTabNames>(
    DashboardActiveTasksListTabNames.ACTIVE_TASKS
  );

  const handleChangeTab = useCallback<
    (tab: DashboardActiveTasksListTabNames) => void
  >(
    (tab) => {
      setSelectTab(tab);
      onSelectedItem({
        nanoId:
          selectTab === DashboardActiveTasksListTabNames.INVITATIONS
            ? activeTasks[0]?.nanoId
            : taskMemberInvites[0]?.task.nanoId,
        scope: DashboardActiveTasksListItemScope.TASK
      });
    },
    [onSelectedItem, selectTab, taskMemberInvites, activeTasks]
  );

  useEffect(() => {
    if (selectedItem) {
      selectTab === DashboardActiveTasksListTabNames.INVITATIONS &&
        every(
          taskMemberInvites,
          (taskMemberInvite) =>
            taskMemberInvite.task.nanoId !== selectedItem.nanoId
        ) &&
        onSelectedItem(null);
    }
  }, [onSelectedItem, selectTab, selectedItem, taskMemberInvites]);

  useEffect(() => {
    selectTab === DashboardActiveTasksListTabNames.INVITATIONS &&
      isEmpty(taskMemberInvites) &&
      handleChangeTab(DashboardActiveTasksListTabNames.ACTIVE_TASKS);
  }, [selectTab, taskMemberInvites, handleChangeTab]);

  return (
    <div className="w-full h-full flex flex-col absolute inset-0 lg:static">
      <div className="py-2 px-3">
        <DashboardActiveTasksListTabs
          activeTab={selectTab}
          changeTab={handleChangeTab}
          activeTasksCount={activeTasksCount}
          invitationsCount={invitationsCount}
          selfProfile={selfProfile}
          cacheKeys={[
            TaskMemberInviteCache.userTaskMemberInvitesCacheKey(user.nanoId)
          ]}
        />
      </div>

      <div className="lg:max-h-[444px] flex-1 overflow-y-auto">
        {selectTab === DashboardActiveTasksListTabNames.ACTIVE_TASKS && (
          <Fragment>
            <DashboardActiveTasksListItemsList
              activeTasks={activeTasks}
              selectedItem={selectedItem}
              onSelectedItem={onSelectedItem}
            />

            <FetchMoreInfiniteButtonHelper
              errorMessage={activeTasksError}
              isLoading={isLoading}
              hasNextPage={hasNextActiveTasksPage}
              i18nText={words.loadMore}
              className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              wrapperClassName="text-center mt-8"
              placement={FetchMoreInfiniteButtonHelperPlacement.BOTTOM}
              onFetchMore={loadMoreActiveTasks}
            />
          </Fragment>
        )}

        {selectTab === DashboardActiveTasksListTabNames.INVITATIONS && (
          <Fragment>
            <TaskMemberInvitesList
              taskMemberInvites={taskMemberInvites}
              selectedItem={selectedItem}
              onSelectedItem={onSelectedItem}
              withActions={selfProfile}
              cacheKeys={[
                TaskMemberInviteCache.userTaskMemberInvitesCacheKey(user.nanoId)
              ]}
            />

            <FetchMoreInfiniteButtonHelper
              errorMessage={taskMemberInvitesError}
              isLoading={isLoading}
              hasNextPage={hasNextTaskMemberInvitesPage}
              i18nText={words.loadMore}
              className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              wrapperClassName="text-center mt-8"
              placement={FetchMoreInfiniteButtonHelperPlacement.BOTTOM}
              onFetchMore={loadMoreTaskMemberInvites}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default DashboardActiveTasksList;
