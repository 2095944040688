import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { AccountNanoID } from '../../../../../../../../accounts/accountsTypes';
import { TeamNanoID } from '../../../../../../../../teams/teamsTypes';
import { DashboardFinanceBalancesAccountTypeChartNumber } from './DashboardFinanceBalancesChart.types';

import {
  FETCH_ACCOUNTS_QUERY,
  FetchAccountsQueryResponse
} from '../../../../../../../../accounts/queries/fetchAccounts.query';

import { useFinPaginatedAccounts } from '../../../../../../../../accounts/hooks/useFinPaginatedAccounts';

import { AccountBalancesChart } from './components/AccountBalancesChart';

import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import { NoResults } from '../../../../../../../../../helpers/NoResults';

import { accountsKeys } from '../../../../../../../../../locales/keys';

import { AccountCache } from '../../../../../../../../accounts/AccountCache';

interface DashboardFinanceBalancesChartProps {
  teamNanoId: TeamNanoID;
  client: boolean;
}

const clientAccountTypeChartNumber = [
  DashboardFinanceBalancesAccountTypeChartNumber.CASH,
  DashboardFinanceBalancesAccountTypeChartNumber.CREDIT_1,
  DashboardFinanceBalancesAccountTypeChartNumber.CREDIT_2
];

const workerAccountTypeChartNumber = [
  DashboardFinanceBalancesAccountTypeChartNumber.CASH,
  DashboardFinanceBalancesAccountTypeChartNumber.RECEIVABLE
];

function DashboardFinanceBalancesChart({
  teamNanoId,
  client
}: DashboardFinanceBalancesChartProps) {
  const { accounts, accountsErrorMessage, accountsFetched } =
    useFinPaginatedAccounts<FetchAccountsQueryResponse>({
      cacheKey: AccountCache.companyDashboardAccountsCacheKey(teamNanoId),
      query: FETCH_ACCOUNTS_QUERY,
      initialFilters: {
        accountTypeChartNumber: {
          in: client
            ? clientAccountTypeChartNumber
            : workerAccountTypeChartNumber
        },
        companyNanoId: { eq: teamNanoId }
      }
    });

  const accountsNanoIds = useMemo<AccountNanoID[]>(
    () => map(accounts, 'nanoId'),
    [accounts]
  );

  return (
    <div className="mb-4">
      <div className="rounded-lg bg-white border-gray-200 dark:bg-gray-850 p-4 dark:border-gray-800 border">
        <AlertMessage addClassName="m-4" message={accountsErrorMessage} />

        <LoadingSkeleton loaded={accountsFetched} addClass="h-80">
          {isEmpty(accountsNanoIds) ? (
            <NoResults noResultsI18nText={accountsKeys.noAccountsYet} />
          ) : (
            <AccountBalancesChart
              accountsNanoIds={accountsNanoIds}
              client={client}
            />
          )}
        </LoadingSkeleton>
      </div>
    </div>
  );
}

export default DashboardFinanceBalancesChart;
