import { gql } from 'graphql-request';

import { MayBe } from '../../../types';

import {
  MessageID,
  MessageUUID,
  MessageBody,
  MessageMessageType,
  MessageFavorite,
  MessageFlagged,
  MessagePinnedAt,
  MessageVisibleForClient,
  MessageColors,
  MessageCreatedAt,
  MessageHours,
  MessageMinutes,
  MessageUserUUID,
  MessageUserFullName,
  MessageUserClient,
  MessageUserImageUUID,
  MessageUserImageFile,
  MessageBillID,
  MessageBillUUID,
  MessageBillAmount,
  MessageBillCurrency,
  MessageBillBody,
  MessageBillStatus,
  MessageBillCreatedAt,
  MessageBillPayerUUID,
  MessageBillPayerNanoID,
  MessageBillPayerFullName,
  MessageBillPayerPayoneerCheckoutAddress,
  MessagePaidBillID,
  MessagePaidBillUUID,
  MessagePaidBillAmount,
  MessagePaidBillCurrency,
  MessagePaidBillPayerUUID,
  MessagePaidBillPayerFullName,
  MessageFileAttachmentsUUID,
  MessageFileAttachmentsType,
  MessageFileAttachmentsName,
  MessageFileAttachmentsFile,
  MessageFileAttachmentsSize,
  MessageFileAttachmentsDrawing,
  MessageFileAttachmentsReference,
  MessageFileAttachmentsTexture,
  MessageFileAttachmentsCreatedAt,
  MessageFileAttachmentsWidth,
  MessageFileAttachmentsHeight,
  MessageFileAttachmentsDPI,
  MessageSelectedProductsUUID,
  MessageSelectedProductsType,
  MessageSelectedProductsCreatedAt,
  MessageSelectedProductsProductUUID,
  MessageSelectedProductsProductImageUUID,
  MessageSelectedProductsProductImageName,
  MessageSelectedProductsProductImageFile,
  MessageSelectedLifestylesUUID,
  MessageSelectedLifestylesType,
  MessageSelectedLifestylesCreatedAt,
  MessageSelectedLifestylesLifestyleUUID,
  MessageSelectedLifestylesLifestyleImageUUID,
  MessageSelectedLifestylesLifestyleImageName,
  MessageSelectedLifestylesLifestyleImageFile,
  MessageSelectedMaterialsUUID,
  MessageSelectedMaterialsType,
  MessageSelectedMaterialsCreatedAt,
  MessageSelectedMaterialsMaterialUUID,
  MessageSelectedMaterialsMaterialImageUUID,
  MessageSelectedMaterialsMaterialImageName,
  MessageSelectedMaterialsMaterialImageFile,
  MessageProjectUUID,
  MessageProjectID,
  MessageBillPayable,
  MessageBillPaymentForm,
  MessageBillPaymentUrl,
  MessageBillAccount,
  MessageIsResult,
  MessageProjectNanoID,
  MessageSelectedProductsProductBrand,
  MessageSelectedProductsProductName,
  MessageSelectedLifestylesLifestyleName,
  MessagePaidBillPayerNanoID,
  MessageUserNanoID,
  MessageFileAttachmentsExt,
  MessageLikeItems,
  MessageFileAttachmentID,
  MessageSelectedProductsProductNda,
  MessageSelectedLifestylesLifestyleNda,
  MessageSelectedMaterialsMaterialNda,
  MessageSelectedMaterialsMaterialName,
  MessageSelectedMaterialID,
  MessageSelectedLifestyleID,
  MessageSelectedProductID,
  MessageSelectedLifestylesLifestyleCategoryLocalizedName,
  MessageInvoiceID,
  MessageInvoiceAmount,
  MessageInvoiceCreatedAt,
  MessageInvoiceUpdatedAt,
  MessageInvoiceStatus,
  MessageInvoiceNanoID,
  MessageInvoiceType,
  MessageInvoicePrepayment,
  MessageInvoiceDueDate,
  MessageInvoicePaymentMethod,
  MessageInvoiceTotal,
  MessageInvoiceClientID,
  MessageInvoiceClientStatus,
  MessageUserUserCurrentTeamNanoID,
  MessageBillPayerClient,
  MessageBillPayerCurrentTeamNanoID,
  MessageInvoicePrepaymentAmount,
  MessageInvoiceReturnReason
} from '../messagesTypes';

export interface FetchProjectMessagesQueryResponse {
  id: MessageID;
  uuid: MessageUUID;
  body: MessageBody;
  messageType: MessageMessageType;
  favorite: MessageFavorite;
  flagged: MessageFlagged;
  pinnedAt?: MessagePinnedAt;
  visibleForClient: MessageVisibleForClient;
  colors: MessageColors;
  createdAt: MessageCreatedAt;
  projectId: MessageProjectID;
  hours?: MessageHours;
  minutes?: MessageMinutes;
  isResult: MessageIsResult;
  invoiceId?: MessageInvoiceID;
  invoice?: {
    amount: MessageInvoiceAmount;
    clientId: MessageInvoiceClientID;
    clientStatus: MessageInvoiceClientStatus;
    createdAt: MessageInvoiceCreatedAt;
    dueDate: MessageInvoiceDueDate;
    invoiceTotal: MessageInvoiceTotal;
    nanoId: MessageInvoiceNanoID;
    paymentMethod: MessageInvoicePaymentMethod;
    prepayment: MessageInvoicePrepayment;
    prepaymentAmount: MessageInvoicePrepaymentAmount;
    returnReason: MessageInvoiceReturnReason;
    status: MessageInvoiceStatus;
    type: MessageInvoiceType;
    updatedAt: MessageInvoiceUpdatedAt;
  };
  messageLikes: MessageLikeItems;
  project: {
    id: MessageProjectID;
    uuid: MessageProjectUUID;
    nanoId: MessageProjectNanoID;
  };
  user: {
    uuid: MessageUserUUID;
    nanoId: MessageUserNanoID;
    fullName: MessageUserFullName;
    client: MessageUserClient;
    currentTeam: MayBe<{
      nanoId: MessageUserUserCurrentTeamNanoID;
    }>;
    image: {
      uuid: MessageUserImageUUID;
      file: MessageUserImageFile;
    };
  };
  bill?: {
    id: MessageBillID;
    uuid: MessageBillUUID;
    account: MessageBillAccount;
    amount: MessageBillAmount;
    currency: MessageBillCurrency;
    body: MessageBillBody;
    payable: MessageBillPayable;
    status: MessageBillStatus;
    createdAt: MessageBillCreatedAt;
    paymentForm: MessageBillPaymentForm;
    paymentUrl: MessageBillPaymentUrl;
    payer: {
      uuid: MessageBillPayerUUID;
      nanoId: MessageBillPayerNanoID;
      fullName: MessageBillPayerFullName;
      payoneerCheckoutAddress: MessageBillPayerPayoneerCheckoutAddress;
      client: MessageBillPayerClient;
      currentTeam: MayBe<{
        nanoId: MessageBillPayerCurrentTeamNanoID;
      }>;
    };
  };
  paidBill?: {
    id: MessagePaidBillID;
    uuid: MessagePaidBillUUID;
    amount: MessagePaidBillAmount;
    currency: MessagePaidBillCurrency;
    payer: {
      uuid: MessagePaidBillPayerUUID;
      nanoId: MessagePaidBillPayerNanoID;
      fullName: MessagePaidBillPayerFullName;
    };
  };
  fileAttachments: {
    id: MessageFileAttachmentID;
    uuid: MessageFileAttachmentsUUID;
    type: MessageFileAttachmentsType;
    name: MessageFileAttachmentsName;
    file: MessageFileAttachmentsFile;
    size: MessageFileAttachmentsSize;
    drawing: MessageFileAttachmentsDrawing;
    reference: MessageFileAttachmentsReference;
    texture: MessageFileAttachmentsTexture;
    createdAt: MessageFileAttachmentsCreatedAt;
    ext: MessageFileAttachmentsExt;
    width: MessageFileAttachmentsWidth;
    height: MessageFileAttachmentsHeight;
    dpi: MessageFileAttachmentsDPI;
  }[];
  selectedProducts: {
    id: MessageSelectedProductID;
    uuid: MessageSelectedProductsUUID;
    type: MessageSelectedProductsType;
    createdAt: MessageSelectedProductsCreatedAt;
    product: {
      uuid: MessageSelectedProductsProductUUID;
      name: MessageSelectedProductsProductName;
      brand: MessageSelectedProductsProductBrand;
      nda: MessageSelectedProductsProductNda;
      image: {
        uuid: MessageSelectedProductsProductImageUUID;
        name: MessageSelectedProductsProductImageName;
        file: MessageSelectedProductsProductImageFile;
      };
    };
  }[];
  selectedLifestyles: {
    id: MessageSelectedLifestyleID;
    uuid: MessageSelectedLifestylesUUID;
    type: MessageSelectedLifestylesType;
    createdAt: MessageSelectedLifestylesCreatedAt;
    lifestyle: {
      uuid: MessageSelectedLifestylesLifestyleUUID;
      name: MessageSelectedLifestylesLifestyleName;
      nda: MessageSelectedLifestylesLifestyleNda;
      category: {
        localizedName: MessageSelectedLifestylesLifestyleCategoryLocalizedName;
      };
      image: {
        uuid: MessageSelectedLifestylesLifestyleImageUUID;
        name: MessageSelectedLifestylesLifestyleImageName;
        file: MessageSelectedLifestylesLifestyleImageFile;
      };
    };
  }[];
  selectedMaterials: {
    id: MessageSelectedMaterialID;
    uuid: MessageSelectedMaterialsUUID;
    type: MessageSelectedMaterialsType;
    createdAt: MessageSelectedMaterialsCreatedAt;
    material: {
      uuid: MessageSelectedMaterialsMaterialUUID;
      nda: MessageSelectedMaterialsMaterialNda;
      name: MessageSelectedMaterialsMaterialName;
      image: {
        uuid: MessageSelectedMaterialsMaterialImageUUID;
        name: MessageSelectedMaterialsMaterialImageName;
        file: MessageSelectedMaterialsMaterialImageFile;
      };
    };
  }[];
  forwardedMessage?: {
    uuid: MessageUUID;
    body: MessageBody;
    createdAt: MessageCreatedAt;
    visibleForClient: MessageVisibleForClient;
    colors: MessageColors;
    user: {
      fullName: MessageUserFullName;
      client: MessageUserClient;
    };
  };
}

export const FETCH_PROJECT_MESSAGES = gql`
  query ProjectMessages(
    $filters: MessagesFilters
    $sort: [MessagesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    messages(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        body
        messageType
        favorite
        flagged
        pinnedAt
        visibleForClient
        colors
        createdAt
        projectId
        hours
        minutes
        isResult
        invoiceId
        invoice {
          amount
          clientId
          clientStatus
          createdAt
          dueDate
          invoiceTotal
          nanoId
          paymentMethod
          prepayment
          prepaymentAmount
          returnReason
          status
          type
          updatedAt
        }
        messageLikes {
          uuid
          messageLikeType
          userId
          user {
            uuid
            fullName
            image {
              file
            }
          }
        }
        project {
          uuid
          nanoId
        }
        user {
          uuid
          nanoId
          fullName
          client
          currentTeam {
            nanoId
          }
          image {
            uuid
            file
          }
        }
        bill {
          id
          uuid
          account
          amount
          currency
          body
          payable
          status
          createdAt
          payer {
            uuid
            nanoId
            fullName
            payoneerCheckoutAddress
            client
            currentTeam {
              nanoId
            }
          }
        }
        paidBill {
          id
          uuid
          amount
          currency
          payer {
            uuid
            nanoId
            fullName
          }
        }
        fileAttachments {
          id
          uuid
          type
          name
          file
          size
          drawing
          reference
          texture
          createdAt
          ext
          width
          height
          dpi
        }
        selectedProducts {
          id
          uuid
          type
          createdAt
          product {
            uuid
            name
            nda
            brand {
              localizedName
            }
            image {
              uuid
              file
            }
          }
        }
        selectedLifestyles {
          id
          uuid
          type
          createdAt
          lifestyle {
            uuid
            name
            nda
            category {
              localizedName
            }
            image {
              uuid
              file
            }
          }
        }
        selectedMaterials {
          id
          uuid
          type
          createdAt
          material {
            uuid
            name
            nda
            image {
              uuid
              file
            }
          }
        }
        forwardedMessage {
          uuid
          body
          createdAt
          visibleForClient
          colors
          user {
            fullName
            client
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
