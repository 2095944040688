import React from 'react';

import { ProjectUUID, ProjectNanoID } from '../../../../../projectsTypes';

import {
  FETCH_PROJECT_MESSAGES,
  FetchProjectMessagesQueryResponse
} from '../../../../../../messages/queries/fetchProjectMessages.query';
import {
  FETCH_USERS_MESSAGES_AUTHORS_QUERY,
  FetchUsersMessagesAuthorsQueryResponse
} from '../../../../../../users/queries/fetchUsersMessagesAuthors.query';

import { InfiniteIndexQueryOnSuccess } from '../../../../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';
import { useMessages } from '../../../../../../messages/hooks/useMessages';
import { useItemsWithBitInvoices } from '../../../../../../invoices/hooks/useItemsWithBitInvoices';
import { useFinUsers } from '../../../../../../users/hooks/useFinUsers';

import {
  ItemMessagesList,
  ItemMessagesListMessages,
  ItemMessagesListOnRemoveSendingMessage
} from '../../../../../../messages/components/list/ItemMessagesList';

import { ItemMessagesHeader } from '../../../../../../messages/components/headers/ItemMessagesHeader';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { ProjectCache } from '../../../../../ProjectCache';
import { UserCache } from '../../../../../../users/UserCache';
import { MessagesPermissions } from '../../../../../../messages/messagesConstants';

interface ProjectMessagesContentMessagesListProps {
  projectNanoId: ProjectNanoID;
  projectUuid: ProjectUUID;
  sendingMessages: ItemMessagesListMessages;
  onFetchMessagesSuccess: InfiniteIndexQueryOnSuccess<FetchProjectMessagesQueryResponse>;
  onRemoveSendingMessage: ItemMessagesListOnRemoveSendingMessage;
}

const projectPinnedMessagesLimit = 100;

function ProjectMessagesContentMessagesList({
  projectNanoId,
  projectUuid,
  sendingMessages,
  onFetchMessagesSuccess,
  onRemoveSendingMessage
}: ProjectMessagesContentMessagesListProps) {
  const {
    messages,
    messagesError,
    messagesFetched,
    messagesFetchingNextPage,
    messagesIsPlaceholderData,
    hasNextMessagesPage,
    loadMoreMessages,
    messagesFilters,
    filterMessages
  } = useMessages<FetchProjectMessagesQueryResponse>({
    cacheKey: ProjectCache.messagesCacheKey(projectNanoId),
    query: FETCH_PROJECT_MESSAGES,
    initialFilters: {
      projectNanoId
    },
    options: { onSuccess: onFetchMessagesSuccess }
  });

  const messagesWithBitInvoices = useItemsWithBitInvoices({ items: messages });

  const {
    messages: pinnedMessages,
    messagesError: pinnedMessagesError,
    messagesFetched: pinnedMessagesFetched,
    messagesTotalCount: pinnedMessagesTotalCount
  } = useMessages<FetchProjectMessagesQueryResponse>({
    cacheKey: ProjectCache.pinnedMessagesCacheKey(projectNanoId),
    query: FETCH_PROJECT_MESSAGES,
    initialLimit: projectPinnedMessagesLimit,
    initialFilters: {
      projectNanoId,
      pinned: true
    }
  });

  const { users, usersError, usersFetched, usersTotalCount } =
    useFinUsers<FetchUsersMessagesAuthorsQueryResponse>({
      cacheKey: UserCache.usersMessagesByProject(projectNanoId),
      query: FETCH_USERS_MESSAGES_AUTHORS_QUERY,
      initialFilters: {
        messagesInProjectNanoId: { eq: projectNanoId }
      },
      initialLimit: 100
    });

  return (
    <>
      <CheckPermissions action={MessagesPermissions.READ_MESSAGES_HEADER}>
        <ItemMessagesHeader
          pinnedMessages={pinnedMessages}
          pinnedMessagesCacheKey={ProjectCache.pinnedMessagesCacheKey(
            projectNanoId
          )}
          pinnedMessagesTotalCount={pinnedMessagesTotalCount}
          forwardMessageProjectUuid={projectUuid}
          messagesFilters={messagesFilters}
          filterMessages={filterMessages}
          users={users}
          usersError={usersError}
          usersFetched={usersFetched}
          usersTotalCount={usersTotalCount}
        />
      </CheckPermissions>
      <ItemMessagesList
        messages={messagesWithBitInvoices}
        messagesCacheKey={ProjectCache.messagesCacheKey(projectNanoId)}
        messagesErrorMessage={messagesError}
        messagesFetched={messagesFetched}
        messagesFetchingNextPage={messagesFetchingNextPage}
        hasNextMessagesPage={hasNextMessagesPage}
        loadMoreMessages={loadMoreMessages}
        messagesIsPlaceholderData={messagesIsPlaceholderData}
        pinnedMessages={pinnedMessages}
        pinnedMessagesCacheKey={ProjectCache.pinnedMessagesCacheKey(
          projectNanoId
        )}
        pinnedMessagesFetched={pinnedMessagesFetched}
        pinnedMessagesErrorMessage={pinnedMessagesError}
        pinnedMessagesTotalCount={pinnedMessagesTotalCount}
        forwardMessageProjectUuid={projectUuid}
        sendingMessages={sendingMessages}
        onRemoveSendingMessage={onRemoveSendingMessage}
      />
    </>
  );
}

export default ProjectMessagesContentMessagesList;
