import React, { useCallback, useMemo } from 'react';

import filter from 'lodash/filter';
import find from 'lodash/find';
import some from 'lodash/some';

import {
  SmartContractRulePresenceTypes,
  SmartContractRuleRuleTypes,
  UpdateSmartContractsCacheKeys
} from '../../../../smartContracts/smartContractsTypes';
import { ClassName, IsDisabled } from '../../../../../types';
import { TaskNanoID } from '../../../tasksTypes';

import {
  ACCEPT_INVITE_TO_TASK_QUERY,
  AcceptInviteToTaskQueryResponse
} from '../../../queries/acceptInviteToTask.query';
import {
  FETCH_TASK_SMART_CONTRACT_QUERY,
  FetchTaskSmartContractQueryResponse
} from '../../../../smartContracts/queries/fetchTaskSmartContract.query';
import {
  FETCH_TASK_SMART_CONTRACTS_QUERY,
  FetchTaskSmartContractsQueryResponse
} from '../../../../smartContracts/queries/fetchTaskSmartContracts.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useFinPaginatedSmartContracts } from '../../../../smartContracts/hooks/useFinPaginatedSmartContracts';
import { useFinSmartContract } from '../../../../smartContracts/hooks/useFinSmartContract';
import { useAcceptInviteToTask } from '../../../hooks/useAcceptInviteToTask';

import { SmartContractCheckInModalButton } from '../../../../smartContracts/components/modalButtons/SmartContractCheckInModalButton';

import { getDefaultSmartContract } from '../../../../smartContracts/utils/getDefaultSmartContract';

import { IconsEnum } from '../../../../../assets/icons/types';
import { SmartContractCache } from '../../../../smartContracts/SmartContractCache';

import { tasksKeys } from '../../../../../locales/keys';

interface TaskCheckInButtonProps {
  taskNanoId: TaskNanoID;
  cacheKeys?: UpdateSmartContractsCacheKeys;
  disabled?: IsDisabled;
  className?: ClassName;
  i18nTextClassName?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
}

function TaskCheckInButton({
  cacheKeys,
  taskNanoId,
  disabled,
  className,
  i18nTextClassName,
  icon,
  iconClassName
}: TaskCheckInButtonProps) {
  const currentUser = useCurrentUser();

  const {
    acceptInviteToTask,
    acceptInviteToTaskLoading,
    acceptInviteToTaskErrorMessage
  } = useAcceptInviteToTask<AcceptInviteToTaskQueryResponse>({
    query: ACCEPT_INVITE_TO_TASK_QUERY,
    cacheKeys
  });

  const {
    smartContracts,
    smartContractsFetched,
    smartContractsIsPlaceholderData,
    smartContractsErrorMessage
  } = useFinPaginatedSmartContracts<FetchTaskSmartContractsQueryResponse>({
    query: FETCH_TASK_SMART_CONTRACTS_QUERY,
    cacheKey: SmartContractCache.taskSmartContractsCacheKey(taskNanoId),
    initialFilters: { taskNanoId: { eq: taskNanoId } },
    options: {
      withoutPrefetch: true
    }
  });

  const selectedSmartContract = useMemo<FetchTaskSmartContractsQueryResponse>(
    () => getDefaultSmartContract(smartContracts),
    [smartContracts]
  );

  const {
    smartContract,
    smartContractFetched,
    smartContractIsPlaceholderData,
    smartContractError
  } = useFinSmartContract<FetchTaskSmartContractQueryResponse>({
    cacheKey: SmartContractCache.showCacheKey(),
    query: FETCH_TASK_SMART_CONTRACT_QUERY,
    uuid: selectedSmartContract?.nanoId,
    options: {
      enabled: !!selectedSmartContract?.nanoId,
      enabledPlaceholder: !!selectedSmartContract?.nanoId
    }
  });

  const userInSelectedSmartContract = useMemo<boolean>(
    () =>
      some(
        smartContract?.smartContractRules,
        (smartContractRule) =>
          smartContractRule.ruleType ===
            SmartContractRuleRuleTypes.CONTRACTOR &&
          smartContractRule.presenceType ===
            SmartContractRulePresenceTypes.ENABLED &&
          smartContractRule.competencyContractor?.nanoId === currentUser.nanoId
      ),

    [currentUser, smartContract]
  );

  const checkInSmartContractRuleId = useMemo(() => {
    const contractorsSmartContractRules = filter(
      smartContract?.smartContractRules,
      (smartContractRule) =>
        smartContractRule.ruleType === SmartContractRuleRuleTypes.CONTRACTOR &&
        smartContractRule.presenceType ===
          SmartContractRulePresenceTypes.ENABLED
    );

    return find(
      contractorsSmartContractRules,
      (smartContractRules) => !smartContractRules.competencyContractor
    )?.id;
  }, [smartContract]);

  const handleAfterCheckIn = useCallback<() => void>(() => {
    acceptInviteToTask({ uuid: taskNanoId });
  }, [acceptInviteToTask, taskNanoId]);

  useShowToastOnErrorChange({
    error:
      smartContractsErrorMessage ||
      smartContractError ||
      acceptInviteToTaskErrorMessage
  });

  return (
    (smartContractsFetched || smartContractsIsPlaceholderData) &&
    selectedSmartContract?.nanoId &&
    (smartContractFetched || smartContractIsPlaceholderData) &&
    !userInSelectedSmartContract && (
      <SmartContractCheckInModalButton
        cacheKeys={[
          SmartContractCache.showCacheKey(),
          SmartContractCache.taskSmartContractsCacheKey(taskNanoId)
        ]}
        smartContractRuleId={checkInSmartContractRuleId}
        disabled={disabled || acceptInviteToTaskLoading}
        userNanoId={currentUser.nanoId}
        className={className}
        i18nTextClassName={i18nTextClassName}
        tooltipI18nText={tasksKeys.buttons.checkIn}
        icon={icon}
        iconClassName={iconClassName}
        afterCheckIn={handleAfterCheckIn}
      />
    )
  );
}

export default TaskCheckInButton;
