import {
  UUID,
  CreatedAt,
  FetchItemsSort,
  FetchItemsPage,
  FetchItemsLimit,
  FetchItemsGqlQuery,
  FetchItemsCacheKey,
  ID,
  UUIDFilter,
  NanoIDFilter,
  FetchItemGqlQuery,
  FetchItemCacheKey,
  FetchItemsFetched,
  FetchItemsErrorMessage,
  FetchItemsIsPlaceholderData,
  FetchItemsTotalCount,
  FetchItemsFilterItems,
  FetchItemsClearItemsFilters,
  FetchItemsSortItems,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsEditItem,
  FetchItemFetched,
  FetchItemIsPlaceholderData,
  DeleteItemCacheKey,
  DeleteItemGqlQuery,
  DeleteItemGqlStatus,
  ItemGqlError,
  UpdateItemGqlQuery,
  UpdateItemCacheKeys,
  UpdateItemGqlStatus,
  StringFilter
} from '../../types';

import {
  TaskBurning,
  TaskCreatedAt,
  TaskDebt,
  TaskDescription,
  TaskID,
  TaskImplementationDate,
  TaskMembersFullName,
  TaskMembersImageFile,
  TaskMembersImageUUID,
  TaskMembersNanoID,
  TaskMembersUUID,
  TaskName,
  TaskNanoID,
  TaskTaskAccount,
  TaskSetDate,
  TaskStatus,
  TaskUserFullName,
  TaskUserImageFile,
  TaskUserImageUUID,
  TaskUserNanoID,
  TaskUserUUID,
  TaskUUID,
  TaskAmount,
  TaskVisibleForClient,
  TaskProjectNanoID,
  TaskProjectName,
  TaskPrepayment,
  TaskClientPrepayment,
  TaskLastActivity,
  TaskLastMessageBody,
  TaskLastMessageCreatedAt,
  TaskMembersRoleName,
  TaskUserRoleName,
  TaskMembersFinanceRoleName,
  TaskUserFinanceRoleName,
  TaskUserClient,
  TaskMembersClient,
  TaskUserCurrentTeamNanoID,
  TaskMembersCurrentTeamNanoID,
  TaskUserID,
  TaskMembersID
} from '../tasks/tasksTypes';

import {
  UserUUID,
  UserFullName,
  UserImageFile,
  UserImageUUID,
  UserID,
  UserNanoID,
  UserClient,
  UserCurrentTeamNanoID
} from '../users/usersTypes';

import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';
import { TeamNanoID } from '../teams/teamsTypes';

export type TaskMemberInviteID = ID;
export type TaskMemberInviteUUID = UUID;
export type TaskMemberInviteCreatedAt = CreatedAt;

export type TaskMemberInviteUserID = UserID;
export type TaskMemberInviteUserUUID = UserUUID;
export type TaskMemberInviteUserNanoID = UserNanoID;
export type TaskMemberInviteUserFullName = UserFullName;
export type TaskMemberInviteUserImageUUID = UserImageUUID;
export type TaskMemberInviteUserImageFile = UserImageFile;
export type TaskMemberInviteUserClient = UserClient;
export type TaskMemberInviteUserCurrentTeamNanoID = UserCurrentTeamNanoID;
export type TaskMemberInviteTaskID = TaskID;
export type TaskMemberInviteTaskUUID = TaskUUID;
export type TaskMemberInviteTaskNanoID = TaskNanoID;
export type TaskMemberInviteTaskName = TaskName;
export type TaskMemberInviteTaskDescription = TaskDescription;
export type TaskMemberInviteTaskCreatedAt = TaskCreatedAt;
export type TaskMemberInviteTaskSetDate = TaskSetDate;
export type TaskMemberInviteTaskImplementationDate = TaskImplementationDate;
export type TaskMemberInviteTaskBurning = TaskBurning;
export type TaskMemberInviteTaskStatus = TaskStatus;
export type TaskMemberInviteTaskVisibleForClient = TaskVisibleForClient;
export type TaskMemberInviteTaskTaskAccount = TaskTaskAccount;
export type TaskMemberInviteTaskDebt = TaskDebt;
export type TaskMemberInviteTaskAmount = TaskAmount;
export type TaskMemberInviteTaskPrepayment = TaskPrepayment;
export type TaskMemberInviteTaskClientPrepayment = TaskClientPrepayment;
export type TaskMemberInviteTaskLastActivity = TaskLastActivity;
export type TaskMemberInviteTaskLastMessageBody = TaskLastMessageBody;
export type TaskMemberInviteTaskLastMessageCreatedAt = TaskLastMessageCreatedAt;
export type TaskMemberInviteTaskProjectNanoID = TaskProjectNanoID;
export type TaskMemberInviteTaskProjectName = TaskProjectName;
export type TaskMemberInviteTaskUserID = TaskUserID;
export type TaskMemberInviteTaskUserUUID = TaskUserUUID;
export type TaskMemberInviteTaskUserNanoID = TaskUserNanoID;
export type TaskMemberInviteTaskUserFullName = TaskUserFullName;
export type TaskMemberInviteTaskUserRoleName = TaskUserRoleName;
export type TaskMemberInviteTaskUserFinanceRoleName = TaskUserFinanceRoleName;
export type TaskMemberInviteTaskUserImageUUID = TaskUserImageUUID;
export type TaskMemberInviteTaskUserImageFile = TaskUserImageFile;
export type TaskMemberInviteTaskUserClient = TaskUserClient;
export type TaskMemberInviteTaskUserCurrentTeamNanoID =
  TaskUserCurrentTeamNanoID;
export type TaskMemberInviteTaskMembersID = TaskMembersID;
export type TaskMemberInviteTaskMembersUUID = TaskMembersUUID;
export type TaskMemberInviteTaskMembersNanoID = TaskMembersNanoID;
export type TaskMemberInviteTaskMembersFullName = TaskMembersFullName;
export type TaskMemberInviteTaskMembersRoleName = TaskMembersRoleName;
export type TaskMemberInviteTaskMembersFinanceRoleName =
  TaskMembersFinanceRoleName;
export type TaskMemberInviteTaskMembersImageUUID = TaskMembersImageUUID;
export type TaskMemberInviteTaskMembersImageFile = TaskMembersImageFile;
export type TaskMemberInviteTaskMembersClient = TaskMembersClient;
export type TaskMemberInviteTaskMembersCurrentTeamNanoID =
  TaskMembersCurrentTeamNanoID;
export type TaskMemberInvitedUserNanoId = UserNanoID;
export type TaskMemberInvitedUserId = UserID;
export type TaskMemberInvitedUserFullName = UserFullName;
export type TaskMemberInvitedUserClient = UserClient;
export type TaskMemberInvitedUserCurrentTeamNanoID = TeamNanoID;

export const enum TaskMemberInviteFields {
  ID = 'id',
  UUID = 'uuid',
  TASK_NAME = 'taskName',
  CREATED_AT = 'createdAt'
}

export type TaskMemberInviteTaskNameFilter = StringFilter;
export type TaskMemberInviteInvitedUserNanoIdFilter = NanoIDFilter;
export type TaskMemberInviteTaskUuidFilter = UUIDFilter;
export type TaskMemberInviteTaskNanoIdFilter = NanoIDFilter;
export type TaskMemberInviteTaskStatusNeFilter = TaskStatus[];
export type TaskMemberInviteCreatedAtFilter = StringFilter;

export interface FetchTaskMemberInvitesFilters {
  taskName?: TaskMemberInviteTaskNameFilter;
  invitedUserNanoId?: TaskMemberInviteInvitedUserNanoIdFilter;
  taskUuid?: TaskMemberInviteTaskUuidFilter;
  taskNanoId?: TaskMemberInviteTaskNanoIdFilter;
  taskStatusNe?: TaskMemberInviteTaskStatusNeFilter;
  createdAt?: TaskMemberInviteCreatedAtFilter;
}

export type FetchTaskMemberInvitesFiltersFunc = (
  nextFilters: FetchTaskMemberInvitesFilters
) => void;

export const enum FetchTaskMemberInvitesSortableFields {
  ID = 'id',
  CREATED_AT = 'createdAt'
}

export const enum FetchTaskMemberInvitesSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchTaskMemberInvitesGqlQuery = FetchItemsGqlQuery;
export type FetchTaskMemberInviteGqlQuery = FetchItemGqlQuery;
export type FetchTaskMemberInvitesCacheKey = FetchItemsCacheKey;
export type FetchTaskMemberInviteCacheKey = FetchItemCacheKey;
export type FetchToggleTaskMemberInviteFavoriteCacheKey = FetchItemCacheKey;

export type FetchTaskMemberInvitesFetched = FetchItemsFetched;
export type FetchTaskMemberInvitesErrorMessage = FetchItemsErrorMessage;
export type FetchTaskMemberInvitesIsPlaceholderData =
  FetchItemsIsPlaceholderData;
export type FetchTaskMemberInvitesPage = FetchItemsPage;
export type FetchTaskMemberInvitesLimit = FetchItemsLimit;
export type FetchTaskMemberInvitesSort = FetchItemsSort; // FetchTaskMemberInvitesSortTypes[];
export type FetchTaskMemberInvitesTotalCount = FetchItemsTotalCount;
export type FetchTaskMemberInvitesFilterTaskMemberInvites =
  FetchItemsFilterItems<FetchTaskMemberInvitesFilters>;
export type FetchTaskMemberInvitesClearTaskMemberInvitesFilters =
  FetchItemsClearItemsFilters;
export type FetchTaskMemberInvitesSortTaskMemberInvites = FetchItemsSortItems;
export type FetchTaskMemberInvitesPaginateTaskMemberInvites =
  FetchItemsPaginateItems;
export type FetchTaskMemberInvitesPrefetchTaskMemberInvites =
  FetchItemsPrefetchItems;
export type FetchTaskMemberInvitesShowTaskMemberInvite = FetchItemsShowItem;
export type FetchTaskMemberInvitesEditTaskMemberInvite = FetchItemsEditItem;

export type FetchTaskMemberInviteFetched = FetchItemFetched;
export type FetchTaskMemberInviteIsPlaceholderData = FetchItemIsPlaceholderData;

export type FetchTaskMemberInvitesChecked = Checked;
export type FetchTaskMemberInvitesCheckedHash = CheckedHashItem;
export type FetchTaskMemberInvitesOnSetCheckedIds = OnSetCheckedIds;
export type FetchTaskMemberInvitesCheckedAll = CheckedAll;
export type FetchTaskMemberInvitesOnCheckAll = OnCheckAll;

export type DeleteTaskMemberInviteGqlQuery = DeleteItemGqlQuery;
export type DeleteTaskMemberInviteGqlStatus = DeleteItemGqlStatus;
export type DeleteTaskMemberInviteGqlError = ItemGqlError;
export type DeleteTaskMemberInviteCacheKey = DeleteItemCacheKey;
export type DeleteTaskMemberInviteCacheKeys = DeleteTaskMemberInviteCacheKey[];

export type UpdateTaskMemberInviteGqlQuery = UpdateItemGqlQuery;
export type UpdateTaskMemberInviteCacheKeys = UpdateItemCacheKeys;
export type UpdateTaskMemberInviteGqlStatus = UpdateItemGqlStatus;
export type TaskMemberInviteGqlError = ItemGqlError;

export type DeclineTaskMemberInviteGqlError = ItemGqlError;
export type DeclineTaskMemberInviteGqlStatus = UpdateItemGqlStatus;
export type DeclineTaskMemberInviteGqlQuery = UpdateItemGqlQuery;
export type DeclineTaskMemberInviteCacheKeys = UpdateItemCacheKeys;

export type DeclineTaskMemberInvitesGqlError = ItemGqlError;
export type DeclineTaskMemberInvitesCacheKeys = UpdateItemCacheKeys;
export type DeclineTaskMemberInvitesGqlQuery = UpdateItemGqlQuery;
export type DeclineTaskMemberInvitesGqlStatus = UpdateItemGqlStatus;
