import { gql } from 'graphql-request';

import {
  TaskMemberInviteUUID,
  TaskMemberInviteCreatedAt,
  TaskMemberInviteTaskID,
  TaskMemberInviteTaskUUID,
  TaskMemberInviteID,
  TaskMemberInviteTaskNanoID,
  TaskMemberInviteTaskName,
  TaskMemberInviteTaskProjectNanoID,
  TaskMemberInviteTaskProjectName
} from '../taskMemberInvitesTypes';

export interface FetchDashboardTaskMemberInvitesQueryResponse {
  id: TaskMemberInviteID;
  uuid: TaskMemberInviteUUID;
  createdAt: TaskMemberInviteCreatedAt;
  task: {
    id: TaskMemberInviteTaskID;
    uuid: TaskMemberInviteTaskUUID;
    nanoId: TaskMemberInviteTaskNanoID;
    name: TaskMemberInviteTaskName;
    project: {
      nanoId: TaskMemberInviteTaskProjectNanoID;
      name: TaskMemberInviteTaskProjectName;
    };
  };
}

export const FETCH_DASHBOARD_TASK_MEMBER_INVITES_QUERY = gql`
  query DashboardTaskMemberInvites(
    $filters: TaskMemberInvitesFilters
    $sort: [TaskMemberInvitesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    taskMemberInvites(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        createdAt
        task {
          id
          uuid
          nanoId
          name
          project {
            nanoId
            name
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
