import React from 'react';

import { UserID, UserNanoID } from '../../../../../usersTypes';
import { TeamNanoID } from '../../../../../../teams/teamsTypes';
import {
  FetchTasksSortTypes,
  TaskStatuses
} from '../../../../../../tasks/tasksTypes';

import {
  FETCH_TASKS_DASHBOARD_QUERY,
  FetchTasksDashboardQueryResponse
} from '../../../../../../tasks/queries/fetchTasksDashboard.query';

import { useTasks } from '../../../../../../tasks/hooks/useTasks';

import { DashboardMissingSourceFilesTask } from './components/DashboardMissingSourceFilesTask';

import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';
import { Translate } from '../../../../../../../helpers/Translate';
import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';

import { TaskCache } from '../../../../../../tasks/TaskCache';
import { TeamPath } from '../../../../../../teams/TeamPath';

import { tasksKeys, words } from '../../../../../../../locales/keys';

interface DashboardMissingSourceFilesUser {
  id: UserID;
  nanoId: UserNanoID;
}

interface DashboardMissingSourceFilesProps {
  user: DashboardMissingSourceFilesUser;
  teamNanoId?: TeamNanoID;
  selfProfile: boolean;
}

const initialLimit = 4;

function DashboardMissingSourceFiles({
  user,
  selfProfile,
  teamNanoId
}: DashboardMissingSourceFilesProps) {
  const { tasks, tasksError, tasksFetched, tasksTotalCount } =
    useTasks<FetchTasksDashboardQueryResponse>({
      cacheKey: TaskCache.dashboardWithoutSourceFiles(),
      query: FETCH_TASKS_DASHBOARD_QUERY,
      initialLimit,
      initialSort: [FetchTasksSortTypes.LAST_ACTIVITY_DESC],
      initialFilters: {
        withoutSourceFiles: true,
        performerIds: [user.id],
        status: { eq: TaskStatuses.DONE }
      },
      options: {
        enabled: true,
        enabledPlaceholder: false
      }
    });

  return (
    <div className="relative rounded-lg bg-white border-gray-200 dark:bg-gray-850 p-4 dark:border-gray-800 border">
      <LoadingSkeleton loaded={tasksFetched} count={3}>
        <div className="flex justify-between items-start mb-2">
          <div className="flex items-center gap-2 text-md dark:text-gray-300">
            <Translate id={tasksKeys.missingSourceFiles} />
          </div>
        </div>

        <div className="flex flex-col 2xl:flex-row justify-between gap-4 2xl:gap-6">
          <div className="flex 2xl:flex-col justify-between gap-4">
            <div className="flex flex-col">
              <div className="text-3xl font-medium">{tasksTotalCount}</div>
              <div className="text-xs text-gray-500">
                <Translate id={tasksKeys.plural} />
              </div>
            </div>

            <div className="self-start w-40"></div>
          </div>

          <div className="flex-1 min-w-0">
            {tasks.map((task) => (
              <DashboardMissingSourceFilesTask
                key={task.nanoId}
                taskName={task.name}
                taskNanoId={task.nanoId}
              />
            ))}

            <div className="text-sm">
              <NextPureLinkHelper
                href={
                  selfProfile
                    ? TeamPath.currentCompanyReportsTasksWithoutSourceFiles()
                    : TeamPath.companyReportsTasksWithoutSourceFiles(teamNanoId)
                }
                className="p-0 inline-flex items-center whitespace-nowrap leading-5 focus:ring-base text-current hover:underline underline"
                i18nText={words.viewAll}
              />
            </div>
          </div>
        </div>
        <AlertMessage message={tasksError} />
      </LoadingSkeleton>
    </div>
  );
}

export default DashboardMissingSourceFiles;
