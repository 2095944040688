import { gql } from 'graphql-request';

import {
  InvoiceAccountTransactionCount,
  InvoiceAmount,
  InvoiceAvInvoiceBillingInfoAddress,
  InvoiceAvInvoiceBillingInfoBankDetails,
  InvoiceAvInvoiceBillingInfoCity,
  InvoiceAvInvoiceBillingInfoCompanyName,
  InvoiceAvInvoiceBillingInfoContactEmail,
  InvoiceAvInvoiceBillingInfoContactName,
  InvoiceAvInvoiceBillingInfoContactPhone,
  InvoiceAvInvoiceBillingInfoCountry,
  InvoiceAvInvoiceBillingInfoID,
  InvoiceAvInvoiceBillingInfoName,
  InvoiceAvInvoiceBillingInfoNanoID,
  InvoiceAvInvoiceBillingInfoPaymentMethods,
  InvoiceAvInvoiceBillingInfoState,
  InvoiceAvInvoiceBillingInfoZipCode,
  InvoiceBurning,
  InvoiceClientId,
  InvoiceClientStatus,
  InvoiceCreatedAt,
  InvoiceDisabledBindButton,
  InvoiceDisabledCancelButton,
  InvoiceDisabledPayCardButton,
  InvoiceDisabledPayWireButton,
  InvoiceDisabledRefundButton,
  InvoiceDisabledSendButton,
  InvoiceDisabledUnbindButton,
  InvoiceDisabledUncollectibleButton,
  InvoiceDisabledVoidButton,
  InvoiceDueDate,
  InvoiceGeneralLedgerCompanyID,
  InvoiceGeneralLedgerCompanyName,
  InvoiceGeneralLedgerCompanyNanoID,
  InvoiceGeneralLedgerID,
  InvoiceID,
  InvoiceInvoiceBillingInfoAddress,
  InvoiceInvoiceBillingInfoCity,
  InvoiceInvoiceBillingInfoCompanyName,
  InvoiceInvoiceBillingInfoContactEmail,
  InvoiceInvoiceBillingInfoContactPhone,
  InvoiceInvoiceBillingInfoCountry,
  InvoiceInvoiceBillingInfoFirstName,
  InvoiceInvoiceBillingInfoID,
  InvoiceInvoiceBillingInfoLastName,
  InvoiceInvoiceBillingInfoNanoID,
  InvoiceInvoiceBillingInfoState,
  InvoiceInvoiceBillingInfoZipCode,
  InvoiceInvoiceType,
  InvoiceItemID,
  InvoiceItemTypeName,
  InvoiceNanoID,
  InvoiceNotes,
  InvoicePaid,
  InvoiceParentInvoiceID,
  InvoicePaymentDate,
  InvoicePaymentMethod,
  InvoicePrepayment,
  InvoicePrepaymentAmount,
  InvoicePrimaryInvoiceID,
  InvoiceProjectID,
  InvoiceReceived,
  InvoiceReturnReasons,
  InvoiceSecondaryInvoiceID,
  InvoiceShowBindButton,
  InvoiceShowCancelButton,
  InvoiceShowPayCardButton,
  InvoiceShowPayWireButton,
  InvoiceShowRefundButton,
  InvoiceShowSendButton,
  InvoiceShowUnbindButton,
  InvoiceShowUncollectibleButton,
  InvoiceShowVoidButton,
  InvoiceSmartContractID,
  InvoiceSmartContractRuleCompetencyName,
  InvoiceSmartContractRuleID,
  InvoiceSmartContractRuleValue,
  InvoiceSmartContractTaskName,
  InvoiceSmartContractTaskNanoID,
  InvoiceSmartContractTaskProjectCompanyID,
  InvoiceSmartContractTaskProjectCompanyName,
  InvoiceSmartContractTaskProjectCompanyNanoID,
  InvoiceSmartContractTaskProjectName,
  InvoiceSmartContractTaskProjectNanoID,
  InvoiceSmartContractTaskUUID,
  InvoiceStatus,
  InvoiceTerms,
  InvoiceTotal,
  InvoiceTransactionFeeExpense,
  InvoiceUUID,
  InvoiceUserId
} from '../invoicesTypes';
import {
  TeamAccountReceivables,
  TeamEmail,
  TeamImageFile,
  TeamUUID
} from '../../teams/teamsTypes';
import { ItemPrice, ItemSmartContractID } from '../../items/itemsTypes';
import { ItemCategoryName } from '../../itemCategories/itemCategoriesTypes';
import { ItemTypeName } from '../../itemTypes/itemTypesTypes';

export interface FetchInvoicesQueryResponse {
  id: InvoiceID;
  nanoId: InvoiceNanoID;
  uuid: InvoiceUUID;
  status: InvoiceStatus;
  dueDate: InvoiceDueDate;
  amount: InvoiceAmount;
  accountTransactionCount: InvoiceAccountTransactionCount;
  createdAt: InvoiceCreatedAt;
  invoiceType: InvoiceInvoiceType;
  invoiceTotal: InvoiceTotal;
  paymentMethod: InvoicePaymentMethod;
  showPayWireButton: InvoiceShowPayWireButton;
  disabledPayWireButton: InvoiceDisabledPayWireButton;
  showUncollectibleButton: InvoiceShowUncollectibleButton;
  disabledUncollectibleButton: InvoiceDisabledUncollectibleButton;
  showBindButton: InvoiceShowBindButton;
  disabledBindButton: InvoiceDisabledBindButton;
  showUnbindButton: InvoiceShowUnbindButton;
  disabledUnbindButton: InvoiceDisabledUnbindButton;
  showPayCardButton: InvoiceShowPayCardButton;
  disabledPayCardButton: InvoiceDisabledPayCardButton;
  showSendButton: InvoiceShowSendButton;
  disabledSendButton: InvoiceDisabledSendButton;
  showRefundButton: InvoiceShowRefundButton;
  disabledRefundButton: InvoiceDisabledRefundButton;
  showCancelButton: InvoiceShowCancelButton;
  disabledCancelButton: InvoiceDisabledCancelButton;
  showVoidButton: InvoiceShowVoidButton;
  disabledVoidButton: InvoiceDisabledVoidButton;
  burning: InvoiceBurning;
  paid: InvoicePaid;
  received: InvoiceReceived;
  transactionFeeExpense: InvoiceTransactionFeeExpense;
  primaryInvoiceId: InvoicePrimaryInvoiceID;
  secondaryInvoiceId: InvoiceSecondaryInvoiceID;
  clientId: InvoiceClientId;
  clientStatus: InvoiceClientStatus;
  paymentDate: InvoicePaymentDate;
  prepayment: InvoicePrepayment;
  returnReason: InvoiceReturnReasons;
  parentInvoiceId: InvoiceParentInvoiceID;
  terms: InvoiceTerms;
  projectId: InvoiceProjectID;
  notes?: InvoiceNotes;
  prepaymentAmount: InvoicePrepaymentAmount;
  userId: InvoiceUserId;
  generalLedgerId: InvoiceGeneralLedgerID;
  generalLedger: {
    company: {
      id: InvoiceGeneralLedgerCompanyID;
      nanoId: InvoiceGeneralLedgerCompanyNanoID;
      name: InvoiceGeneralLedgerCompanyName;
      accountReceivables: TeamAccountReceivables;
      uuid: TeamUUID;
      email: TeamEmail;
      image: {
        file: TeamImageFile;
      } | null;
    };
  };
  invoiceBillingInfo: {
    id: InvoiceInvoiceBillingInfoID;
    nanoId: InvoiceInvoiceBillingInfoNanoID;
    companyName: InvoiceInvoiceBillingInfoCompanyName;
    firstName: InvoiceInvoiceBillingInfoFirstName;
    lastName: InvoiceInvoiceBillingInfoLastName;
    contactPhone: InvoiceInvoiceBillingInfoContactPhone;
    contactEmail: InvoiceInvoiceBillingInfoContactEmail;
    country: InvoiceInvoiceBillingInfoCountry;
    state: InvoiceInvoiceBillingInfoState;
    city: InvoiceInvoiceBillingInfoCity;
    address: InvoiceInvoiceBillingInfoAddress;
    zipCode: InvoiceInvoiceBillingInfoZipCode;
  };
  avInvoiceBillingInfo: {
    id: InvoiceAvInvoiceBillingInfoID;
    nanoId: InvoiceAvInvoiceBillingInfoNanoID;
    name: InvoiceAvInvoiceBillingInfoName;
    companyName: InvoiceAvInvoiceBillingInfoCompanyName;
    contactName: InvoiceAvInvoiceBillingInfoContactName;
    contactPhone: InvoiceAvInvoiceBillingInfoContactPhone;
    contactEmail: InvoiceAvInvoiceBillingInfoContactEmail;
    country: InvoiceAvInvoiceBillingInfoCountry;
    state: InvoiceAvInvoiceBillingInfoState;
    city: InvoiceAvInvoiceBillingInfoCity;
    address: InvoiceAvInvoiceBillingInfoAddress;
    zipCode: InvoiceAvInvoiceBillingInfoZipCode;
    paymentMethod: InvoiceAvInvoiceBillingInfoPaymentMethods;
    bankDetails: InvoiceAvInvoiceBillingInfoBankDetails;
  };
  secondaryInvoice?: {
    id: InvoiceID;
    nanoId: InvoiceNanoID;
    amount: InvoiceAmount;
    showPayWireButton: InvoiceShowPayWireButton;
    disabledPayWireButton: InvoiceDisabledPayWireButton;
    showPayCardButton: InvoiceShowPayCardButton;
    disabledPayCardButton: InvoiceDisabledPayCardButton;
    clientStatus: InvoiceClientStatus;
    status: InvoiceStatus;
  };
  childInvoices: {
    id: InvoiceID;
    item: {
      itemTypeName: ItemTypeName;
      itemCategoryName: ItemCategoryName;
      price: ItemPrice;
      smartContractId: ItemSmartContractID;
    };
    smartContract: {
      id: InvoiceSmartContractID;
      task: {
        nanoId: InvoiceSmartContractTaskNanoID;
        name: InvoiceSmartContractTaskName;
        project: {
          nanoId: InvoiceSmartContractTaskProjectNanoID;
          name: InvoiceSmartContractTaskProjectName;
        };
      };
    };
  }[];
  smartContract: {
    task: {
      nanoId: InvoiceSmartContractTaskNanoID;
      name: InvoiceSmartContractTaskName;
      uuid: InvoiceSmartContractTaskUUID;
      project: {
        nanoId: InvoiceSmartContractTaskProjectNanoID;
        name: InvoiceSmartContractTaskProjectName;
        company: {
          id: InvoiceSmartContractTaskProjectCompanyID;
          nanoId: InvoiceSmartContractTaskProjectCompanyNanoID;
          name: InvoiceSmartContractTaskProjectCompanyName;
        };
      };
    };
  };
  smartContractRule: {
    id: InvoiceSmartContractRuleID;
    competencyName: InvoiceSmartContractRuleCompetencyName;
    value: InvoiceSmartContractRuleValue;
  };
  item: {
    id: InvoiceItemID;
    itemTypeName: InvoiceItemTypeName;
  };
}

export const FETCH_INVOICES_QUERY = gql`
  query Invoices(
    $filters: InvoicesFilters
    $sort: [InvoicesSortEnum!]
    $offset: Int
    $limit: Int
  ) {
    invoices(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      appVersion
      nodes {
        accountTransactionCount
        amount
        avInvoiceBillingInfo {
          address
          bankDetails
          city
          companyName
          contactName
          contactEmail
          contactPhone
          country
          id
          name
          nanoId
          paymentMethod
          state
          zipCode
        }
        burning
        childInvoices {
          id
          item {
            itemTypeName
            itemCategoryName
            price
            smartContractId
          }
          smartContract {
            id
            task {
              nanoId
              name
              project {
                nanoId
                name
              }
            }
          }
        }
        clientId
        clientStatus
        createdAt
        deferredPaymentDays
        description
        disabledPayWireButton
        disabledUncollectibleButton
        disabledBindButton
        disabledUnbindButton
        disabledPayCardButton
        disabledSendButton
        disabledRefundButton
        disabledCancelButton
        disabledVoidButton
        dueDate
        generalLedgerId
        generalLedger {
          company {
            id
            nanoId
            name
            accountReceivables
            uuid
          }
        }
        id
        nanoId
        invoiceBillingInfo {
          address
          city
          companyName
          contactEmail
          contactPhone
          country
          firstName
          id
          lastName
          nanoId
          state
          zipCode
        }
        invoiceTotal
        invoiceType
        nanoId
        notes
        paid
        parentInvoiceId
        paymentDate
        prepayment
        prepaymentAmount
        primaryInvoiceId
        projectId
        received
        returnReason
        secondaryInvoice {
          amount
          clientStatus
          disabledPayCardButton
          disabledPayWireButton
          id
          nanoId
          showPayCardButton
          showPayWireButton
          status
        }
        secondaryInvoiceId
        showPayWireButton
        showUncollectibleButton
        showBindButton
        showUnbindButton
        showPayCardButton
        showSendButton
        showRefundButton
        showCancelButton
        showVoidButton
        smartContract {
          task {
            nanoId
            name
            project {
              nanoId
              name
              company {
                id
                nanoId
                name
              }
            }
            uuid
          }
        }
        smartContractRule {
          id
          competencyName
          value
        }
        status
        terms
        transactionFeeExpense
        userId
        uuid
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      sql
    }
  }
`;
