import { ClassName, I18nText, UpdateItemCacheKeys } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { ACCEPT_ALL_TASK_MEMBER_INVITES_QUERY } from '../../../queries/acceptAllTaskMemberInvites.query';

import { useAcceptAllTaskMemberInvites } from '../../../hooks/useAcceptAllTaskMemberInvites';

import { ConfirmModalButton } from '../../../../../helpers/buttons/ConfirmModalButton';

import { TaskMemberInviteCache } from '../../../TaskMemberInviteCache';
import { taskMemberInvitesKeys } from '../../../../../locales/keys';

interface AcceptAllTaskMemberInvitesButtonProps {
  className?: ClassName;
  i18nButtonText?: I18nText;
  tooltipI18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  cacheKeys?: UpdateItemCacheKeys;
}

function AcceptAllTaskMemberInvitesButton({
  className,
  i18nButtonText,
  tooltipI18nText,
  icon,
  iconClassName,
  cacheKeys
}: AcceptAllTaskMemberInvitesButtonProps) {
  const {
    acceptAllTaskMemberInvites,
    acceptAllTaskMemberInvitesErrorMessage,
    acceptAllTaskMemberInvitesLoading
  } = useAcceptAllTaskMemberInvites({
    query: ACCEPT_ALL_TASK_MEMBER_INVITES_QUERY,
    cacheKeys: cacheKeys ? cacheKeys : [TaskMemberInviteCache.indexCacheKey()]
  });

  return (
    <ConfirmModalButton
      errorMessage={acceptAllTaskMemberInvitesErrorMessage}
      i18nSubmitText={taskMemberInvitesKeys.acceptAll}
      i18nButtonText={i18nButtonText}
      className={className}
      tooltipI18nText={tooltipI18nText}
      i18nText={
        taskMemberInvitesKeys.areTouSureYouWantToAcceptAllTaskInvitationsThisActionCannotBeUndone
      }
      icon={icon}
      iconClassName={iconClassName}
      i18nTitle={taskMemberInvitesKeys.acceptAll}
      isLoading={acceptAllTaskMemberInvitesLoading}
      submitButtonColor="blue"
      onSubmit={acceptAllTaskMemberInvites}
    />
  );
}

export default AcceptAllTaskMemberInvitesButton;
