import { gql } from 'graphql-request';

import {
  SmartContractID,
  SmartContractNanoID,
  SmartContractUUID,
  SmartContractItemUpdatedAt,
  SmartContractItemID,
  SmartContractItemNanoID,
  SmartContractItemUUID,
  SmartContractItemPrice,
  SmartContractItemPrepayment,
  SmartContractItemItemTypeName,
  SmartContractItemItemCategoryName,
  SmartContractItemDescription,
  SmartContractItemUnbindedAt,
  SmartContractItemInvoiceID,
  SmartContractItemInvoiceUUID,
  SmartContractItemInvoiceNanoID,
  SmartContractItemInvoiceStatus,
  SmartContractItemInvoiceInvoiceType,
  SmartContractItemInvoiceParentInvoiceID,
  SmartContractItemInvoiceAmount,
  SmartContractItemInvoiceReturnReason,
  SmartContractItemShowVoidButton,
  SmartContractShowUnbindButton,
  SmartContractStatus,
  SmartContractShowCancelButton,
  SmartContractDisabledCancelButton,
  SmartContractShareStrategy,
  SmartContractShareStrategyValue,
  SmartContractInternalAt,
  SmartContractRuleID,
  SmartContractRuleNanoID,
  SmartContractRuleRuleType,
  SmartContractRulePresenceType,
  SmartContractRuleCreatedAt,
  SmartContractRuleValue,
  SmartContractRuleShareOverrideAt,
  SmartContractRuleName,
  SmartContractRuleFeeTypes,
  SmartContractRuleCompetencyContractorNanoID,
  SmartContractShowTransactionFeeButton,
  SmartContractDisabledTransactionFeeButton,
  SmartContractShowProvisionForBadDebtButton,
  SmartContractDisabledProvisionForBadDebtButton,
  SmartContractShowProvisionForRevisionButton,
  SmartContractDisabledProvisionForRevisionButton,
  SmartContractShowAvShareSchemeButton,
  SmartContractDisabledAvShareSchemeButton,
  SmartContractShowInternalSmartContractButton,
  SmartContractDisabledInternalSmartContractButton,
  SmartContractInvoiceID,
  SmartContractInvoiceStatus,
  SmartContractInvoiceInvoiceType,
  SmartContractInvoiceAmount,
  SmartContractInvoicePaymentMethod,
  SmartContractInvoicePrimaryInvoiceID,
  SmartContractInvoicePrepayment,
  SmartContractInvoiceClientId,
  SmartContractInvoiceParentInvoiceID,
  SmartContractInvoiceGeneralLedgerID,
  SmartContractShowChangeUserShareButton,
  SmartContractDisabledChangeUserShareButton,
  SmartContractShowRemoveFromContractButton,
  SmartContractDisabledRemoveFromContractButton,
  SmartContractShowCheckInButton,
  SmartContractDisabledCheckInButton,
  SmartContractShowPayFromAvContractorsInvoiceButton,
  SmartContractDisabledPayFromAvContractorsInvoiceButton,
  SmartContractShowPayFromPfrContractorsInvoiceButton,
  SmartContractDisabledPayFromPfrContractorsInvoiceButton,
  SmartContractShowPayFromPfbdContractorsInvoiceButton,
  SmartContractDisabledPayFromPfbdContractorsInvoiceButton,
  SmartContractRuleCompetencyContractorName,
  SmartContractRuleCompetencyContractorID,
  SmartContractRuleCompetencyContractorSurName,
  SmartContractRuleContractorCompetencyID,
  SmartContractInvoiceNanoID,
  SmartContractInvoiceUUID,
  SmartContractContractorsAmountAmount,
  SmartContractContractorsAmountContractorCompetencyID,
  SmartContractShowAddItemButton,
  SmartContractDisabledAddItemButton,
  SmartContractDisabledUnbindButton,
  SmartContractShowMoveItemButton,
  SmartContractDisabledMoveItemButton,
  SmartContractTaskShowStartButton,
  SmartContractTaskDisabledStartButton,
  SmartContractRuleCompetencyContractorCurrentTeamNanoID
} from '../smartContractsTypes';

export interface FetchTaskSmartContractQueryResponse {
  id: SmartContractID;
  uuid: SmartContractUUID;
  nanoId: SmartContractNanoID;
  status: SmartContractStatus;
  shareStrategy: SmartContractShareStrategy;
  shareStrategyValue: SmartContractShareStrategyValue;
  internalAt: SmartContractInternalAt;
  contractorsAmounts: {
    amount: SmartContractContractorsAmountAmount;
    contractorCompetencyId: SmartContractContractorsAmountContractorCompetencyID;
  }[];
  smartContractRules: {
    id: SmartContractRuleID;
    nanoId: SmartContractRuleNanoID;
    ruleType: SmartContractRuleRuleType;
    presenceType: SmartContractRulePresenceType;
    createdAt: SmartContractRuleCreatedAt;
    value: SmartContractRuleValue;
    shareOverrideAt: SmartContractRuleShareOverrideAt;
    competencyName: SmartContractRuleName;
    feeType: SmartContractRuleFeeTypes;
    contractorCompetencyId: SmartContractRuleContractorCompetencyID;
    competencyContractor: {
      id: SmartContractRuleCompetencyContractorID;
      nanoId: SmartContractRuleCompetencyContractorNanoID;
      name: SmartContractRuleCompetencyContractorName;
      surname: SmartContractRuleCompetencyContractorSurName;
      currentTeam: {
        nanoId: SmartContractRuleCompetencyContractorCurrentTeamNanoID;
      };
    };
  }[];
  items: {
    updatedAt: SmartContractItemUpdatedAt;
    id: SmartContractItemID;
    nanoId: SmartContractItemNanoID;
    uuid: SmartContractItemUUID;
    price: SmartContractItemPrice;
    prepayment: SmartContractItemPrepayment;
    itemTypeName: SmartContractItemItemTypeName;
    itemCategoryName: SmartContractItemItemCategoryName;
    description: SmartContractItemDescription;
    unbindedAt: SmartContractItemUnbindedAt;
    invoices: {
      id: SmartContractItemInvoiceID;
      uuid: SmartContractItemInvoiceUUID;
      nanoId: SmartContractItemInvoiceNanoID;
      status: SmartContractItemInvoiceStatus;
      invoiceType: SmartContractItemInvoiceInvoiceType;
      parentInvoiceId: SmartContractItemInvoiceParentInvoiceID;
      amount: SmartContractItemInvoiceAmount;
      returnReason: SmartContractItemInvoiceReturnReason;
    }[];
    showVoidButton: SmartContractItemShowVoidButton;
  }[];
  invoices: {
    id: SmartContractInvoiceID;
    nanoId: SmartContractInvoiceNanoID;
    uuid: SmartContractInvoiceUUID;
    status: SmartContractInvoiceStatus;
    invoiceType: SmartContractInvoiceInvoiceType;
    amount: SmartContractInvoiceAmount;
    paymentMethod: SmartContractInvoicePaymentMethod;
    primaryInvoiceId: SmartContractInvoicePrimaryInvoiceID;
    prepayment: SmartContractInvoicePrepayment;
    clientId: SmartContractInvoiceClientId;
    parentInvoiceId: SmartContractInvoiceParentInvoiceID;
    generalLedgerId: SmartContractInvoiceGeneralLedgerID;
    smartContractRuleId: SmartContractRuleID;
  }[];
  task: {
    showStartButton: SmartContractTaskShowStartButton;
    disabledStartButton: SmartContractTaskDisabledStartButton;
  };
  showUnbindButton: SmartContractShowUnbindButton;
  showCancelButton: SmartContractShowCancelButton;
  disabledCancelButton: SmartContractDisabledCancelButton;
  showTransactionFeeButton: SmartContractShowTransactionFeeButton;
  disabledTransactionFeeButton: SmartContractDisabledTransactionFeeButton;
  showProvisionForBadDebtButton: SmartContractShowProvisionForBadDebtButton;
  disabledProvisionForBadDebtButton: SmartContractDisabledProvisionForBadDebtButton;
  showProvisionForRevisionButton: SmartContractShowProvisionForRevisionButton;
  disabledProvisionForRevisionButton: SmartContractDisabledProvisionForRevisionButton;
  showAvShareSchemeButton: SmartContractShowAvShareSchemeButton;
  disabledAvShareSchemeButton: SmartContractDisabledAvShareSchemeButton;
  showInternalSmartContractButton: SmartContractShowInternalSmartContractButton;
  disabledInternalSmartContractButton: SmartContractDisabledInternalSmartContractButton;
  showChangeUserShareButton: SmartContractShowChangeUserShareButton;
  disabledChangeUserShareButton: SmartContractDisabledChangeUserShareButton;
  showRemoveFromContractButton: SmartContractShowRemoveFromContractButton;
  disabledRemoveFromContractButton: SmartContractDisabledRemoveFromContractButton;
  showCheckInButton: SmartContractShowCheckInButton;
  disabledCheckInButton: SmartContractDisabledCheckInButton;
  showPayFromAvContractorsInvoiceButton: SmartContractShowPayFromAvContractorsInvoiceButton;
  disabledPayFromAvContractorsInvoiceButton: SmartContractDisabledPayFromAvContractorsInvoiceButton;
  showPayFromPfrContractorsInvoiceButton: SmartContractShowPayFromPfrContractorsInvoiceButton;
  disabledPayFromPfrContractorsInvoiceButton: SmartContractDisabledPayFromPfrContractorsInvoiceButton;
  showPayFromPfbdContractorsInvoiceButton: SmartContractShowPayFromPfbdContractorsInvoiceButton;
  disabledPayFromPfbdContractorsInvoiceButton: SmartContractDisabledPayFromPfbdContractorsInvoiceButton;
  showAddItemButton: SmartContractShowAddItemButton;
  disabledAddItemButton: SmartContractDisabledAddItemButton;
  disabledUnbindButton: SmartContractDisabledUnbindButton;
  showMoveItemButton: SmartContractShowMoveItemButton;
  disabledMoveItemButton: SmartContractDisabledMoveItemButton;
}

export const FETCH_TASK_SMART_CONTRACT_QUERY = gql`
  query TaskSmartContract($uuid: ID!) {
    smartContract(uuid: $uuid) {
      id
      uuid
      nanoId
      status
      shareStrategy
      shareStrategyValue
      internalAt
      contractorsAmounts {
        amount
        contractorCompetencyId
      }
      smartContractRules {
        id
        nanoId
        ruleType
        presenceType
        createdAt
        value
        shareOverrideAt
        competencyName
        feeType
        contractorCompetencyId
        competencyContractor {
          id
          nanoId
          name
          surname
          currentTeam {
            nanoId
          }
        }
      }
      items {
        updatedAt
        id
        nanoId
        uuid
        price
        prepayment
        itemTypeName
        itemCategoryName
        description
        unbindedAt
        invoices {
          id
          uuid
          nanoId
          status
          invoiceType
          parentInvoiceId
          amount
          returnReason
        }
        showVoidButton
      }
      invoices {
        id
        nanoId
        uuid
        status
        invoiceType
        amount
        paymentMethod
        primaryInvoiceId
        prepayment
        clientId
        parentInvoiceId
        generalLedgerId
        smartContractRuleId
      }
      task {
        showStartButton
        disabledStartButton
      }
      showUnbindButton
      showCancelButton
      disabledCancelButton
      showTransactionFeeButton
      disabledTransactionFeeButton
      showProvisionForBadDebtButton
      disabledProvisionForBadDebtButton
      showProvisionForRevisionButton
      disabledProvisionForRevisionButton
      showAvShareSchemeButton
      disabledAvShareSchemeButton
      showInternalSmartContractButton
      disabledInternalSmartContractButton
      showChangeUserShareButton
      disabledChangeUserShareButton
      showRemoveFromContractButton
      disabledRemoveFromContractButton
      showCheckInButton
      disabledCheckInButton
      showPayFromAvContractorsInvoiceButton
      disabledPayFromAvContractorsInvoiceButton
      showPayFromPfrContractorsInvoiceButton
      disabledPayFromPfrContractorsInvoiceButton
      showPayFromPfbdContractorsInvoiceButton
      disabledPayFromPfbdContractorsInvoiceButton
      showAddItemButton
      disabledAddItemButton
      disabledUnbindButton
      showMoveItemButton
      disabledMoveItemButton
    }
  }
`;
