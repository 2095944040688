import find from 'lodash/find';
import head from 'lodash/head';

import {
  SmartContractID,
  SmartContractStatus,
  SmartContractStatuses
} from '../../smartContractsTypes';

interface GetDefaultSmartContractSmartContract {
  id: SmartContractID;
  status: SmartContractStatus;
}

function getDefaultSmartContract<
  SmartContractType extends GetDefaultSmartContractSmartContract
>(smartContracts: SmartContractType[]): SmartContractType {
  return (
    find(
      smartContracts,
      (smartContract) => smartContract.status === SmartContractStatuses.OPEN
    ) || head(smartContracts)
  );
}

export default getDefaultSmartContract;
