import isThisYear from 'date-fns/isThisYear';
import isToday from 'date-fns/isToday';

function getCustomDateFormat(date: Date | string): string {
  const currentDate = new Date(date);

  if (!isThisYear(currentDate)) {
    return 'dd MMM yyyy';
  }

  if (!isToday(currentDate)) {
    return 'dd MMM';
  }

  return 'HH:mm';
}

export default getCustomDateFormat;
