import { useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { I18nText } from '../../../../../types';

import {
  DeclineTaskMemberInviteCacheKeys,
  TaskMemberInviteUUID
} from '../../../taskMemberInvitesTypes';

import { DECLINE_TASK_MEMBER_INVITE_QUERY } from '../../../queries/declineTaskMemberInvite.query';

import {
  DeclineTaskMemberInviteResponse,
  useDeclineTaskMemberInvite
} from '../../../hooks/useDeclineTaskMemberInvite';

import { ConfirmModalButton } from '../../../../../helpers/buttons/ConfirmModalButton';

import { TaskMemberInviteCache } from '../../../TaskMemberInviteCache';
import { taskMemberInvitesKeys } from '../../../../../locales/keys';

interface DeleteTaskMemberInviteButtonProps {
  uuid: TaskMemberInviteUUID;
  cacheKeys?: DeclineTaskMemberInviteCacheKeys;
  tooltipI18nText?: I18nText;
}

function DeclineTaskMemberInviteButton({
  uuid,
  cacheKeys,
  tooltipI18nText
}: DeleteTaskMemberInviteButtonProps) {
  const {
    declineTaskMemberInvite,
    declineTaskMemberInviteErrorMessage,
    declineTaskMemberInviteLoading
  } = useDeclineTaskMemberInvite({
    query: DECLINE_TASK_MEMBER_INVITE_QUERY,
    cacheKeys: cacheKeys ? cacheKeys : [TaskMemberInviteCache.indexCacheKey()]
  });

  const handleDeclineTaskMemberInvite = useCallback<
    () => Promise<DeclineTaskMemberInviteResponse>
  >(
    () => declineTaskMemberInvite({ uuid: uuid }),
    [declineTaskMemberInvite, uuid]
  );

  return (
    <ConfirmModalButton
      errorMessage={declineTaskMemberInviteErrorMessage}
      i18nSubmitText={taskMemberInvitesKeys.decline}
      icon={IconsEnum.CROSS}
      iconClassName="h-5 w-5"
      className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
      i18nText={
        taskMemberInvitesKeys.areYouSureYouWantToDeclineTaskInvitationThisActionCannotBeUndone
      }
      i18nTitle={taskMemberInvitesKeys.decline}
      tooltipI18nText={tooltipI18nText}
      isLoading={declineTaskMemberInviteLoading}
      modalIcon={IconsEnum.EXCLAMATION}
      modalIconClassName="h-6 w-6 text-red-600"
      onSubmit={handleDeclineTaskMemberInvite}
    />
  );
}

export default DeclineTaskMemberInviteButton;
