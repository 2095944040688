import React, { ReactNode } from 'react';

import { useMainScrollElement } from '../../../../app/hooks/useMainScroll';

import { ScrollToTop } from '../../../../helpers/scrolls/ScrollToTop';
import { ClassName } from '../../../../types';

interface MainScrollWrapperProps {
  className?: ClassName;
  withScrollToTop?: boolean;
  children: ReactNode;
}

function MainScrollWrapper({
  className,
  withScrollToTop,
  children
}: MainScrollWrapperProps) {
  const { mainScrollElement, setMainScrollElement } = useMainScrollElement();

  return (
    <main
      ref={setMainScrollElement}
      className={
        className ||
        'flex-1 relative overflow-y-auto focus:outline-none z-0 xl:z-15'
      }
      tabIndex={-1}
    >
      {children}
      {withScrollToTop ? (
        <ScrollToTop scrollContainerElement={mainScrollElement} />
      ) : null}
    </main>
  );
}

export default MainScrollWrapper;
